import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import qs from 'query-string';
import { Container, Box, MenuItem } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import Text from '../components/common/Text';
import appStyles from '../../../config/appStyles.js';
import { useStores } from '../stores';
import Button from '../components/common/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import theme from './../theme';
import Icon from '../components/common/Icon';
import Select from '../components/common/Select';
import { apiService } from '../services';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => appStyles.unsubscribe(theme));

const Unsubscribe = () => {
  const { t } = useTranslation(['common', 'components', 'validations']);
  const classes = useStyles();
  const { uiStore, userStore } = useStores();
  const [reason, setReason] = useState('');

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openSubs, setOpenSubs] = useState(false);
  const [products, setProducts] = useState([]);
  const [prod, setProd] = useState('');
  const [unsubNotifyAll, setUnsubNotifyAll] = useState(true);

  const { email, uid, preference, type, group, segment } = qs.parse(
    window.location.search,
  );

  const homePage = t('components:Footer.homePage', {
    returnObjects: true,
    defaultValue: {},
  });

  const unsubscribe = async () => {
    let subscriptions;
    if (type === 'notifyme') {
      subscriptions = {
        notifyme: unsubNotifyAll
      };
    } else {
      subscriptions = {
        [type]: false,
      };
    }
    let audience = {
      group,
      segment: unsubNotifyAll ? segment : null,
      remove: true,
    };

    const data = {
      email: email,
      uid: uid,
      subscriptions,
      audience,
      reason: reason,
      locale: uiStore.language,
      site: uiStore.site,
      apikey: process.env.REACT_GPROXY_NEWSLETTER_KEY,
    };

    try {
      uiStore.setLoader(true);
      await userStore.subscribe(data);
      uiStore.setLoader(false);
      uiStore.openModal(
        t('components:Unsubscribe.successfullModal.text'),
        'brà',
        {
          onConfirm: setOpenSubs(false),
        },
      );
    } catch (err) {
      console.log(err);
      uiStore.setLoader(false);
      uiStore.openModal(err.errorDetails, '', {
        isError: true,
      });
    }
  };

  useEffect(() => {
    apiService.getProductByCountry(uiStore.site).then((res) => {
      console.log('api service', res.data);

      const product = res.data.filter((filter) => filter.code === segment);
      setProd(product[0]);
    });
  }, [uiStore.site, segment]);

  return (
    <React.Fragment>
      <Dialog
        maxWidth={fullScreen ? '' : 'lg'}
        fullScreen={fullScreen}
        classes={{ paper: classes.dialogPaper }}
        open={openSubs}
        onClose={() => setOpenSubs(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Icon
              onClick={() => {
                setOpenSubs(false);
              }}
              className={classes.iconDialog}
              name="close"></Icon>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description">
            <h4 className={classes.dialogMainTitle}>
              {t('components:EmailComunication.updatePref')}
            </h4>
            <Box className={classes.boxBtnDialog} width="100%">
              <Button
                variant="outlined"
                className={classes.btnCancel}
                onClick={() => setOpenSubs(false)}>
                <span>{t('components:EmailComunication.cancel')}</span>
              </Button>
              <Button
                className={classes.btnConfirm}
                onClick={() => unsubscribe()}
                autoFocus>
                <span>{t('components:EmailComunication.yesApply')}</span>
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Container className={classes.container}>
        {type === 'newsletter' && (
          <Box>
            <Text className={classes.title} variant="titleXXXL">
              {t('components:Unsubscribe.newsletterTitle')}
            </Text>
            <Text
              dangerouslySetInnerHTML={{
                __html: t('components:Unsubscribe.pageNote', { email }),
              }}
              className={classes.subtitle}
              variant="titleXL"></Text>
            <ValidatorForm>
              <Select
                className={classes.select}
                label={t('components:Unsubscribe.reasonType')}
                name={'reasonType'}
                onChange={(e) => setReason(e.target.value)}>
                {Object.entries(
                  t('components:Unsubscribe.reasonsTypes', {
                    returnObjects: true,
                    defaultValue: {},
                  }),
                ).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </ValidatorForm>
          </Box>
        )}

        {type === 'marketing' && (
          <Box>
            <Text className={classes.title} variant="titleXXXL">
              {t('components:Unsubscribe.titleMarketing')}
            </Text>
            <Text className={classes.subtitle} variant="titleXL">
              {t('components:Unsubscribe.subtitleMarketing')}
            </Text>
            <ValidatorForm>
              <Select
                className={classes.select}
                label={t('components:Unsubscribe.reasonType')}
                name={'reasonType'}
                onChange={(e) => setReason(e.target.value)}>
                {Object.entries(
                  t('components:Unsubscribe.reasonsTypes', {
                    returnObjects: true,
                    defaultValue: {},
                  }),
                ).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </ValidatorForm>
          </Box>
        )}

        {type === 'notifyme' && (
          <Box>
            <Text className={classes.title} variant="titleXXXL">
              {t('components:Unsubscribe.titleNotify')}
            </Text>
            <Text className={classes.subtitle} variant="titleXL">
              {t('components:Unsubscribe.subtitleNotify')}
            </Text>
          </Box>
        )}

        {type === 'notifyme' ? (
          <Box
            display={'flex'}
            flexDirection="column"
            alignItems={'flex-start'}
            style={{ gap: '16px' }}>
            <Button
              onClick={() => {
                setUnsubNotifyAll(true);
                setOpenSubs(true);
              }}
              next>
              {t('components:Unsubscribe.CTAsingleNoti', {
                name: prod && prod.name,
              })}
            </Button>
            <Button
              className={classes.notyfyAllBtn}
              onClick={() => {
                setUnsubNotifyAll(false);
                setOpenSubs(true);
              }}>
              {t('components:Unsubscribe.CTAMultiNoti')}
            </Button>
          </Box>
        ) : (
          <Button
            onClick={() => {
              setOpenSubs(true);
            }}
            next>
            {t('components:Unsubscribe.unsubscribe')}
          </Button>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Unsubscribe;
