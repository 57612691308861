import { pasaDistributor } from './appStyles/PasaDistributorStyle';

import {
  editablePanel,
  editablePanelDetails,
  editablePanelSummaryActions,
  editablePanelSummary,
  editablePanelActions,
  editablePanelRadio,
} from './appStyles/editablePanel';

import {
  packageHeader,
  packageItemCard,
  nextPackage,
  packagePackage,
  packageStepper,
  packageStatusDetails,
  packageFullKit,
  packageRegular,
} from './appStyles/package';

import {
  autocomplete,
  button,
  countdown,
  dialog,
  drawerHeader,
  drawer,
  fixedMobileCTA,
  fluidImage,
  gallery,
  iconButton,
  image,
  input,
  link,
  linkButton,
  module,
  overlay,
  select,
  slider,
  text,
  statusChip,
  topSpacing,
} from './appStyles/utils';

import { figureSelector, figureGrid, figureCard } from './appStyles/figure';

import {
  faqsPrivate,
  faqs,
  emailCommunication,
  profileInfo,
  publicContactForm,
  userBanner,
  app,
  dashboard,
  subscription,
  resetPassword,
  publicHelpCenter,
  publicContactUs,
  loginAgent,
  login,
  helpCenter,
  collection,
  tutorials,
  footer,
} from './appStyles/layout';

const paymentResult = (theme) => ({
  mainTitle: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Poppins',
    lineHeight: 1.07,
    textAlign: 'center',
    marginTop: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      // textAlign: 'center',
      fontSize: 18,
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
  },
});

const invalidCurrentPassword = () => ({
  title: {
    margin: 0,
    fontSize: '12px',
    fontStyle: 'normal',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    lineHeight: 1.17,
    letterSpacing: 'normal',
    color: '#f93f3f',
  },
});

const addressList = (theme) => ({
  root: {},
  addressContainerMobile: {
    padding: 32,
  },
  autocompleteCustom: {
    '& label + .MuiInput-formControl': {
      marginTop: '0 !important',
    },
  },
  addressPanel: {
    fontFamily: 'Poppins',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  inputAddress: {
    width: '100%',
  },
  labelAddr: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.customPalette.neutralGy05,
    paddingLeft: theme.spacing(1),
    textTransform: 'capitalize',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      fontWeight: 'normal',
      paddingLeft: theme.spacing(2),
      textTransform: 'uppercase',
    },
  },
  gridAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  defaultLabel: {
    position: 'absolute',
    top: '-14%',
    left: '2%',
    backgroundColor: '#fff',
    border: '1px solid' + theme.customPalette.primaryGreenG01,
    color: theme.customPalette.primaryGreenG01,
    padding: '2px 6px',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    display: 'none',
    height: 24,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  summary: {
    border: '1px solid' + theme.customPalette.neutralGr03,
    // border: "none",
    // [theme.breakpoints.up("sm")]: {
    //     border: "1px solid" + theme.customPalette.secondaryYellowYButtons,
    // },
  },
  details: {
    border: '1px solid' + theme.customPalette.neutralGr03,
    borderTop: 'none',
    padding: theme.spacing(0),
    // border: "none",
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    },
  },
  actions: {},
  passwordContainer: {
    marginTop: theme.spacing(1),
  },
  summaryContainer: {
    // paddingTop: "16px",
    // paddingBottom: "16px",
    margin: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  prefixInput: {
    width: '100% !important',
    padding: '14px 55px !important',
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    '&$hover': {
      borderTop: 0,
      borderRight: 0,
      borderLeft: 0,
    },
    '&$focus': {
      borderTop: 0,
      borderRight: 0,
      borderLeft: 0,
    },
    ...theme.overrides.MuiFilledInput.root,
  },
  prefixInputContainer: {
    marginTop: 17,
  },
  isDefault: {
    backgroundColor: '#bfbfbf',
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid white`,
    },
  },
  summaryElement: { paddingTop: '16px', paddingBottom: '16px' },
  errorPhone: {
    fontSize: 12,
    color: theme.customPalette.coral,
  },
  outlinedHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.neutralGr03}`,
    paddingTop: '16px',
    paddingBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  /* Styles applied to the root element if `variant="filled"`. */
  filled: {},
  /* Styles applied to the root element if `variant="outlined"`. */
  outlined: {
    '& $summaryElement': {
      padding: theme.spacing(3, 2),
    },
  },
  expanded: {
    backgroundColor: 'red',
  },
});

const alert = (theme) => ({
  modal: {
    position: 'relative',
  },
  modalHeader: {},
  modalBody: {
    textAlign: 'center',
    padding: theme.spacing(7, 8, 8, 8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& a, a:visited': {
      color: theme.customPalette.secondaryYellowYButtons,
    }
  },
  modalText: {
    fontWeight: '700',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: "20px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  icon: {
    width: 62,
    height: 62,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(0, 8, 8, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2, 1, 2),
      justifyContent: 'space-around',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: `1px solid ${theme.customPalette.neutralGy05}`,
    },
  },
  action: {
    '&:nth-child(odd)': {
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(5),
      },
    },
    '&:only-child': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
});

const alertBar = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    width: '100%',
    background: theme.customPalette.secondaryPinkP02,
    color: 'white',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 48,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 104,
    height: 65,
    minHeight: 65,
    minWidth: 104,
    marginTop: -1,
    marginLeft: -1,
  },
  icon: {
    width: 24,
    height: 24,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: theme.spacing(3),
  },
  title: {},
  linkClose: {
    '& p': {
      color: '#fff',
    },
  },
  message: {
    color: '#fff',
  },
  actionsWrapper: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
  },
  /* Styles applied to the root element if `severity="error"`*/
  severityError: {
    '& $container': {
      border: `2px solid ${theme.customPalette.secondaryPinkP02}`,
    },
    '& $iconWrapper': {
      backgroundColor: theme.customPalette.secondaryPinkP02,
    },
  },
});

const infoBar = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    width: '100%',
    background: theme.customPalette.secondaryBlueB02,
    color: 'white',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 48,
    maxWidth: 740,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    '@media (min-width: 992px)': {
      maxWidth: 960,
      padding: 0,
    },
    '@media (min-width: 1200px)': {
      maxWidth: 1140,
      padding: 0,
    },
    '@media (min-width: 1600px)': {
      maxWidth: 1280,
      padding: 0,
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //width: 104,
    //height: 65,
    minHeight: 65,
    //minWidth: 104,
    marginTop: -1,
    marginLeft: 18,
  },
  icon: {
    width: 24,
    height: 24,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    //marginLeft: theme.spacing(3),
  },
  title: {
    color: '#fff',
  },
  linkClose: {
    '& p': {
      color: '#fff',
    },
  },
  message: {
    color: '#fff',
    fontSize: '13px',
    '& a': {
      color: '#fff',
    },
  },
  actionsWrapper: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
  },
  /* Styles applied to the root element if `severity="error"`*/
  severityError: {
    '& $container': {
      border: `2px solid ${theme.customPalette.secondaryPinkP02}`,
    },
    '& $iconWrapper': {
      backgroundColor: theme.customPalette.secondaryPinkP02,
    },
  },
});

const articleCard = (theme) => ({
  container: {
    backgroundColor: theme.customPalette.neutralGr02,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    '&.isBig': {
      display: 'flex',
      height: '100%',
    },
  },
  imageContainer: {
    height: 230,
    position: 'relative',
    '&.isBig': {
      width: '50%',
      flexShrink: 0,
      height: '100%',
    },
  },
  bodyContainer: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'column',
    '&.isBig': {
      padding: theme.spacing(6, 4),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  body: {
    lineHeight: 1.5,
    flexGrow: 1,
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&.isBig': {
      justifyContent: 'flex-start',
    },
  },
  ctaBox: {
    paddingBottom: theme.spacing(1 / 2),
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    '&.isBig': {
      justifyContent: 'flex-start',
    },
  },
  badgeBox: {
    position: 'absolute',
    left: theme.spacing(3),
    bottom: 0,
    padding: theme.spacing(1 / 4, 1 / 2),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&.isBig': {
      position: 'relative',
      left: 'initial',
      width: 'fit-content',
      marginBottom: theme.spacing(2),
    },
  },
  ribbonRoot: {},
  ribbon: {
    top: 42,
    left: -32,
    width: 170,
  },
});

const assembleHelp = (theme) => ({
  container: {
    display: 'flex',
    minHeight: 114,
    background: theme.customPalette.neutralGr02,
    width: 1160,
    padding: '32px 40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (min-width: 501px) and (max-width: 1208px)': {
      width: 'auto',
      padding: 16,
      marginLeft: 24,
      marginRight: 24,
    },
    '@media (max-width: 500px)': {
      width: 'auto',
      padding: 16,
      marginLeft: 16,
      marginRight: 16,
    },
  },
  content: {
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 2,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    marginLeft: 40,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
    },
  },
  cancelButtonWrapper: {
    marginLeft: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      flexGrow: '1',
    },
  },
  image: {
    width: 56,
    height: 56,
  },
  button: {
    width: 220,
    minHeight: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
      marginBottom: 32,
      width: '100%',
    },
  },
  buttonAlone: {
    width: 220,
    minHeight: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
      marginBottom: 0,
      width: '100%',
    },
  },
  cancelButton: {
    whiteSpace: 'nowrap',
  },
});

const estimatedArrival = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  container: {
    maxWidth: '320px',
    backgroundColor: theme.customPalette.neutralGr02,
    padding: '20px 24px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
    },
  },
});

const giftList = (theme) => ({
  gifts: {
    textAlign: 'center',
    overflow: 'hidden',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  title: {
    paddingBottom: theme.spacing(4),
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
  },
  slider: {},
  gift: {
    height: '100%',
    padding: '2px',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
    },
  },
  mobileSingleGift: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const giftOverlay = (theme) => ({
  giftOverlay: {},
  appBar: {
    position: 'relative',
    padding: '0 8px 0 8px !important',
    background: 'white',
    borderBottom: `1px solid ${theme.customPalette.neutralGy05}`,
    [theme.breakpoints.down('sm')]: {
      borderTop: `27px solid ${theme.customPalette.neutralGy05}`,
    },
  },
  body: {
    padding: theme.spacing(0, 4, 4, 4),
  },
  icon: {
    height: 24,
    width: 24,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  image: {
    height: 380,
    width: 400,
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
  name: {},
  descriptio: {},
});

const infoCard = (theme) => ({
  infoCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: theme.spacing(5, 7),
    width: '100%',
    height: '100%',
    backgroundColor: theme.customPalette.neutralGr02,
    border: `3px solid ${theme.customPalette.primaryGreenG02}`,
  },
  number: {
    marginBottom: theme.spacing(3),
  },
  description: {
    lineHeight: 1.5,
  },
});

const infoCards = (theme) => ({
  infoCards: {
    marginBottom: theme.spacing(5),
  },
  title: {},
  subtitle: {},
  cards: {
    marginTop: theme.spacing(7),
  },
  sliderElement: {
    maxWidth: 500,
    height: '100%',
    paddingRight: theme.spacing(1),
  },
});

const logo = (theme) => ({
  logo: {
    cursor: 'pointer',
    height: '126px !important',
    width: '100% !important',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: '60px !important',
      margin: 'auto',
    },
  },
});

const logoutButton = (theme) => ({
  logout: {
    width: 142,
    height: 47,
    padding: theme.spacing(1, 2, 1, 2),
    color: 'white',
    borderRadius: 0,
    boxShadow: 'none!important',
    backgroundColor: theme.customPalette.primaryGreenG02,
    [theme.breakpoints.down('sm')]: {
      width: 32,
      height: 32,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: `${theme.customPalette.primaryGreenG03}!important`,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
      },
    },
  },
  logoutIcon: {
    width: 24,
    height: 24,
    [theme.breakpoints.down('sm')]: {
      width: 32,
      height: 32,
    },
  },
  logoutButtonText: {
    marginLeft: theme.spacing(2),
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#ffffff',
  },
});

const mobileSlider = (theme) => ({
  container: {
    overflow: 'hidden',
  },
  sliderButton: {
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sliderNextButton: {
    borderRadius: '100%',
    border: `3px solid ${theme.customPalette.secondaryYellowYButtons}`,
  },
  slider: {
    '& .slick-list': {
      overflow: 'visible',
    },
    marginBottom: theme.spacing(3),
  },
});

const nextGift = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(8, 16),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 2),
      overflowX: 'hidden',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
      textAlign: 'center',
    },
  },
  box: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    height: 60,
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 30,
    },
  },
  hashtag: {
    borderRight: '1px solid #F1F1F1',
  },
  figure: {
    maxHeight: 300,
    width: 'auto',
    maxWidth: '100%',
  },
  figureIntro: {
    width: '20%',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  slider: {
    '& .slick-list': {
      overflow: 'visible',
      width: '85%',
    },
  },
  gift: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  giftTitle: {
    width: '20%',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      paddingRight: 0,
    },
  },
  giftContent: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  giftImg: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 200,
    },
  },
  overlayBackground: {
    zIndex: 1,
    background: 'rgba(200,200,200,0.8)',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  overlayButton: {
    background: '#fff',
    borderRadius: '25px',
    cursor: 'pointer',
    maxWidth: '250px',
    margin: 'auto',
    textAlign: 'center',
    marginTop: '20%',
  },
  overlayContainer: {
    width: '100%',
    position: 'relative',
  },
  flex: {
    display: 'flex',
  },
});

const nextPackageDetails = () => ({
  root: {
    width: '100%',
    background: '#aaa',
    marginTop: '20px',
  },
  bold: {
    fontWeight: 'bold',
  },
  divider: {
    borderRight: '2px solid black',
    height: '100%',
  },
  flex: {
    display: 'flex',
  },
});

const payment = () => ({
  root: {},
  paymentContainer: {
    width: '100%',
  },
  progress: {
    maxWidth: 50,
  },
});

const paymentList = (theme) => ({
  passwordContainer: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 0,
      width: 100,
      height: 100,
    },
  },
  bigAvatar: {
    margin: theme.spacing(2, 2),
    width: 128,
    height: 128,
  },
  summaryContainer: {
    margin: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  prefixInput: {
    width: '100% !important',
    padding: '14px 55px !important',
  },
  prefixInputContainer: {
    marginTop: 17,
  },
  isDefault: {
    backgroundColor: '#bfbfbf',
  },
});

const playButton = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  playButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    // background: theme.customPalette.secondaryYellowYButtons,
    width: '49px',
    height: '49px',
    borderRadius: '50%',
  },
  playText: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
  },
  playIcon: {
    width: 27,
    height: 27,
  },
});

const profileElement = (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 0, 2, 3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0, 2, 4),
    },
  },
  verticalDivider: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid white`,
    },
  },
  horizontalDivider: {
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid white`,
    },
  },
  label: {
    fontSize: '16px',
    fontWeight: 'normal',
    paddingBottom: '8px',
    color: theme.customPalette.neutralGy05,
  },
  value: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1,
    wordBreak: 'break-word',
    color: theme.customPalette.neutralGy05,
  },
  panelActionsClose: {
    position: 'absolute',
    right: 0,
  },
});

const progress = (theme, loading) => ({
  /* Styles applied to the root element. */
  root: {
    height: 'calc(100vh - 200px)',
    backgroundImage: "url('" + loading + "')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '200px 200px',
  },
  fullscreen: {},
  hover: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: theme.zIndex.modal + 1,
    margin: 0,
  },
  small: {
    height: 70,
    width: 70,
    backgroundSize: '65px 65px',
  },
  medium: {
    height: 100,
    width: 100,
    backgroundSize: '95px 95px',
  },
});

const ribbon = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    position: 'absolute',
    overflow: 'hidden',
    height: '200px',
    width: '150px',
    background: 'transparent',
    zIndex: '1',
  },
  ribbon: {
    background: theme.customPalette.primaryGreenG02,
    transform: 'rotate3d(0,0,1,-45deg)',
    position: 'absolute',
    textAlign: 'center',
    top: 26,
    left: -35,
    width: 150,
  },
  text: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle',
  },
});

const socialBox = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(8, 16),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 2),
    },
    backgroundColor: '#F9F9F9',
  },
  box: {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  icon: {
    height: 60,
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 30,
    },
  },
  hashtag: {
    borderRight: '1px solid #F1F1F1',
  },
});

const stamp = (theme) => ({
  stamp: {
    color: '#fff',
    backgroundColor: theme.customPalette.primaryGreenG02,
    ...theme.fonts.faces.bodyM,
    width: 68,
    height: 68,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 14,
    position: 'absolute',
  },
});

const subscriptionHeader = (theme) => ({
  /* Styles applied to the root element. */
  root: {},
  title: {},
  container: {
    display: 'flex',
  },
  label: {
    marginRight: theme.spacing(3),
  },
  value: {},
  status: {
    marginLeft: theme.spacing(2),
  },
});

const video = (theme) => ({
  video: {},
  image: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
  },
  foreground: {
    /* zIndex: 99  --> why is needed here? */
  },
  playerWrapper: {
    position: 'relative',
  },
  player: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  playWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    zIndex: 3,
  },
  fullscreenWrapper: {
    display: 'grid',
    objectPosition: 'center',
    gridTemplate: 'auto 1fr / auto',
    width: '100%',
    height: '100%',
    //overflow: "auto",
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  fullscreenVideoWrapper: {
    position: 'relative',
    backgroundColor: 'black',
    width: '100%',
    height: '100%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '60vw',
    },
  },
  grow: {
    flexGrow: 1,
  },
  fullscreenBar: {
    position: 'relative',
    display: 'flex',
    height: 30,
    padding: '0 8px 0 8px !important',
    backgroundColor: 'black',
    [theme.breakpoints.down('sm')]: {
      height: 80,
      backgroundColor: 'transparent',
    },
  },
  playButtonFullscreenWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  closeButton: {
    height: 'fit-content',
    margin: 'auto',
  },
  closeIcon: {
    height: 24,
    width: 24,
  },
  badge: {
    position: 'absolute',
    // backgroundColor: theme.customPalette.primaryGreenG02,
    fontFamily: 'Poppins',
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '22px',
    padding: '16px 8px',
    zIndex: 1,
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(to top, #000, rgba(196, 196, 196, 0))',
  },
});

const videoDescription = (theme) => ({
  VideoDescription: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gridGap: theme.spacing(12),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(4),
    },
  },
  editorialContent: {
    paddingRight: theme.spacing(15),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(0, 1, 0, 1),
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    '&:before': {
      [theme.breakpoints.down('md')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  text: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      lineHeight: '1.57 !important',
      textAlign: 'center',
      fontSize: '0.9rem !important',
    },
  },
  videoWrapper: {
    position: 'relative',
    height: 430,
    [theme.breakpoints.down('md')]: {
      paddingBottom: '56.25%' /* 16:9 */,
      height: 0,
    },
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
});

const complexItemCard = (theme) => ({
  itemCard: {
    background: 'inherit',
    height: 400,
    textAlign: 'center',
    border: 'none',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
    },
  },
  itemCardMedia: {
    height: 365,
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  videoClass: {
    width: 487,
    height: 365,
    '@media screen and (min-width: 960px) and (max-width: 1280px)': {
      width: 487,
      height: 313,
    },
    '@media screen and (min-width: 600px) and (max-width: 960px)': {
      height: 'calc(75vw - 88px)',
      marginTop: 48,
      width: '100%',
    },
    '@media screen and (max-width: 600px)': {
      height: 'calc(75vw - 88px)',
      marginTop: 0,
      width: '100%',
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    margin: 8,
  },
  cardContentRoot: {
    padding: 0,
  },
});

const notification = (theme) => ({
  notificationWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  notification: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#fff',
    background: theme.customPalette.secondaryPinkP02,
    border: `1px solid ${theme.customPalette.secondaryPinkP02}`,
    width: '100%',
  },
  notificationIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
    minWidth: 48,
    marginLeft: -1,
  },
  notificationIcon: {
    width: 24,
    height: 24,
  },
  notificationText: {
    padding: theme.spacing(1),
    height: 'unset',
    color: '#fff',
    fontSize: 14,
    fontFamily: 'Poppins',
    lineHeight: 1.14,
    fontWeight: 'normal',
  },
});

const aboutModule = (theme) => ({
  about: {
    marginTop: theme.spacing(1 / 2),
    marginBottom: theme.spacing(1 / 2),
    minHeight: 600,
    alignItems: 'center',
    display: 'flex',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      float: 'initial !important',
    },
  },
  editorialContent: {
    float: 'left',
    width: '40%',
    marginRight: '4%',
    marginTop: 'auto',
    marginBottom: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      color: 'white',
      padding: theme.spacing(4, 4, 0, 4),
      marginRight: 'initial',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    '&:before': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  text: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      padding: theme.spacing(4, 0, 0, 0),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 18,
      lineHeight: 1.22,
    },
  },
  imageWrapper: {
    position: 'relative',
    //overflow: 'hidden',
    float: 'right',
    width: '55%',
  },
  parallaxWrapper: {
    position: 'absolute',
    right: -theme.spacing(8),
    top: '50%',
    transform: 'translate3d(0, -50%, 0)',
    //maxHeight: 450,
    width: '100%',
    /*bottom: 0,
    left: theme.spacing(8),
    right: '-25vw',
    margin: 'auto',
    height: '100%',
    zIndex: -1,*/
  },
  background: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '90%', // FIX ** to be align with visual...
    zIndex: -1,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  foreground: {
    //height: 650, // FIX ** keep it fluid
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '125%',
    },
  },
  button: {
    marginTop: 24,
    fontSize: 16,
    fontWeight: 500,
    padding: '16px',
  },
});

const assembleVideoBanner = (theme) => ({
  container: {
    minHeight: 160,
    background: 'none',
    width: '100%',
    padding: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: 16,
      marginRight: 'auto',
    },
  },
  desktopBackground: {
    zIndex: -1,
    height: 240,
    width: '100%',
    position: 'absolute',
    background: theme.customPalette.neutralGr02,
    [theme.breakpoints.down('sm')]: {
      height: 0,
      background: 'none',
    },
  },
  content: {
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  titleContainer: {},
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 32,
    maxWidth: 430,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
  button: {
    width: 220,
    minHeight: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
      marginBottom: 0,
      width: '100%',
    },
  },
  centered: {
    paddingTop: 16,
    zIndex: 100,
    height: '100%',
    display: 'flex',
    alignItems: 'start',
  },
  videoContainer: {
    margin: 4,
    [theme.breakpoints.down('sm')]: {
      margin: 4,
    },
  },
  videoClass: {
    [theme.breakpoints.up('md')]: {
      height: 256,
    },
    '@media screen and (min-width: 960px) and (max-width: 1280px)': {
      height: 'calc(25vw - 46px)',
    },
    '@media screen and (max-width: 960px)': {
      height: 'calc(75vw - 40px)',
    },
  },
  progressBar: {
    height: '100%',
    margin: 'auto',
  },
  sliderReference: {
    '& .slick-slide': {
      margin: '0 3px',
    },
  },
  videoSlider: {
    margin: '0 3px',
  },
});

const appBar = (theme, drawerWidth) => ({
  appBar: {
    borderBottom: `1px solid ${theme.customPalette.neutralGr02}`,
    backgroundColor: '#fff!important',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    easing: theme.transitions.easing.sharp,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  logo: {
    width: 163,
    height: 71,
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      width: 124,
      height: 57,
      marginTop: 8,
    },
  },
  appBarLink: {
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {},
  },
  topSpace: {
    backgroundColor: `${theme.customPalette.primaryGreenG03} !important`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3, 0, 2),
    color: 'black',
    backgroundColor: theme.customPalette.primaryGreenG03,
  },
  hide: {
    display: 'none',
  },
  menu: {
    width: 32,
    height: 32,
  },
  close: {
    width: 32,
    height: 32,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  myAreaHeaderLink: {
    color: '#000',
    cursor: 'pointer',
    '&:visited': { color: '#000' },
  },
});

const changeAddressModal = (theme) => ({
  modal: {},
  title: {},
  addButtonPlaceholder: {
    width: 1,
    marginRight: 'auto',
  },
  modalBody: {
    minWidth: `min(60vw, ${theme.breakpoints.values.md}px)`,
    height: '100%',
    padding: theme.spacing(3, 7),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(2),
    },
  },
  buttonSectionFooter: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  gridButton: {
    padding: 0 + '!important',
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // padding: theme.spacing(7, 2),
    padding: '24px 56px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      padding: theme.spacing(2, 2),
    },
  },
  currentPlanContainer: {},
  borderBottom: {
    borderBottom: '1px solid #E5E5E5',
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: theme.spacing(3),
  },
  cancelButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      flexGrow: '1',
    },
  },
  addButton: {
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 277,
    },
  },
  confirmButton: {
    fontSize: 18,
    fontWeight: 600,
    minWidth: 'auto',
    width: '100%',
    marginLeft: 8,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: 218,
    },
  },
  cancelButton: {
    fontSize: 18,
    fontWeight: 600,
    minWidth: 'auto',
    width: '100%',
    padding: '8px 16px 8px 16px',
    marginRight: 8,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: 218,
    },
  },
});

const changePaymentMethodModal = (theme) => ({
  modal: {},
  title: {
    color: 'black',
  },
  modalBody: {
    minWidth: `min(60vw, ${theme.breakpoints.values.md}px)`,
    height: '100%',
    padding: theme.spacing(3, 7),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(2),
    },
  },
  gridButton: {
    padding: 0 + '!important',
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // padding: theme.spacing(7, 2),
    padding: '24px 56px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      padding: theme.spacing(2, 2),
    },
  },
  currentPlanContainer: {},
  borderBottom: {
    borderBottom: '1px solid #E5E5E5',
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: theme.spacing(3),
  },
  cancelButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      flexGrow: '1',
    },
  },
  buttonSectionFooter: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  confirmButton: {
    fontSize: 18,
    fontWeight: 600,
    minWidth: 'auto',
    width: '100%',
    marginLeft: 8,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: 218,
    },
  },
  cancelButton: {
    fontSize: 18,
    fontWeight: 600,
    minWidth: 'auto',
    width: '100%',
    padding: '8px 16px 8px 16px',
    marginRight: 8,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: 218,
    },
  },
});

const collectionTabs = (theme) => ({
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  collectionContainer: {
    transform: 'translateY(1px)',
    marginTop: '16px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  singleTab: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  collectionTab: {
    fontSize: 14,
    fontWeight: 600,
    //fontWeight: 400,
    // height: 56,
    // marginLeft: theme.spacing(2),

    color: '#fff',
    textTransform: 'capitalize',
    width: 'auto',
    // borderBottom: `2px solid ${theme.customPalette.primaryGreenG03}`,
    backgroundColor: 'rgba(255,255,255)',
    borderRadius: '0px',
    padding: 0,
    border: 'solid 0.5px #3b4154',
    // borderBottom: '0px',
    '& :hover': {},
  },
  avatarWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& div': {
      height: '100%',
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        objectPosition: 'center',
      },
    },
    '& span': {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#3b4154',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      padding: '8px',
      lineHeight: 1,
    },
  },
  imgCont: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  selected: {
    backgroundColor: 'white',
    fontWeight: 600,
    borderBottom: 'none',
    color: `${theme.customPalette.secondaryYellowYButtons}!important`,
    '& span': {
      fontWeight: 700,
      color: `#3b4154!important`,
    },
  },
  indicator: {
    height: '1px',
  },
  collectionIcon: {
    marginLeft: theme.spacing(4),
  },
  scrollButtons: { color: `${theme.customPalette.secondaryYellowYButtons}` },
});

const contactForm = (theme) => ({
  contactForm: {
    background: 'trasparent',
  },
  uploadTitle: {
    display: 'block',
    marginBottom: theme.spacing(2),
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
  },
  dragInputActive: {
    backgroundColor: theme.customPalette.neutralGr03,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  },
  dragInputDeactive: {
    backgroundColor: theme.customPalette.neutralGr01,
  },
  dropzone: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropzoneUploadBtnTitle: {
    wordBreak: 'break-all',
    fontSize: 16,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
  },
  dragInput: {
    position: 'relative',
    width: '100%',
    border: '1px dashed' + theme.customPalette.secondaryYellowYButtons,
    height: '56px',
    transition: 'all 0.4s ease-out',
    transform: 'scale(1)',
    cursor: 'pointer',
  },
  label: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
  icon: {
    marginRight: theme.spacing(1),
    transform: 'rotate(180deg)',
  },
  fileAccepted: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontFamily: 'Poppins',
  },

  upTitle: {
    fontSize: '18px',
    fontWeight: 600,
    marginTop: 8,
    fontFamily: 'Poppins',
  },
  input: {
    '& :placeholder': {
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      color: theme.customPalette.neutralGr04,
    },
  },
  mainTitle: {
    fontSize: '30px',
    fontWeight: 600,
    color: theme.customPalette.primaryGreenG02,
    fontFamily: 'Poppins',
  },
  container: {
    padding: theme.spacing(10, 0, 10, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0, 5, 0),
    },
  },
  topicSelect: {
    paddingRight: theme.spacing(0),
  },
  subscriptionSelect: {
    paddingRight: theme.spacing(0),

    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(3),
    },
  },
  messageInput: {
    height: 'fit-content',
    width: '100%',
  },
  submitButton: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2, 9),
  },
  placeholder: {
    color: '#aaa',
  },
});

const contactsInfo = (theme) => ({
  contactsInfo: {
    backgroundColor: theme.customPalette.neutralGy05,
    padding: theme.spacing(9, 0),
    color: theme.customPalette.neutralGr01,
    fontFamily: 'Poppins',
    marginBottom: -50,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 0),
    },
  },
  container: {
    maxWidth: 1500,
    margin: '0 auto',
    padding: theme.spacing(0, 11),
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      padding: theme.spacing(0, 5),
    },
  },
  contactsAndQrCodeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3em',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      paddingBottom: '20px',
      gap: '1em',
    },
  },
  qrCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      paddingBottom: '16px',
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '100%',
      order: 3,
    },
  },
  qrCodeImage: {
    maxWidth: '150px',
    maxHeight: '150px',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    '& img': {
      objectFit: 'contain',
    },
  },
  qrCodeDescription: {
    display: 'flex',
    width: '100%',
    paddingTop: '16px',
    whiteSpace: 'pre-wrap',
    fontSize: '14px',
  },
  contactsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      paddingTop: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'flex-start',
      paddingBottom: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  contacts: {
    width: 'fit-content',
  },
  title: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: 1.05,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.1,
    maxWidth: 350,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      paddingBottom: theme.spacing(2),
    },
  },
  firstContact: {
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
    },
  },
  contact: {
    gap: '16px',
    fontSize: 18,
    fontWeight: 600,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'flex-end',
  },
  whatsUpContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '20px',
    width: 'fit-content',
  },
  whatsUpDescription: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
  },
  iconContainer: {
    backgroundColor: 'rgb(37, 211, 102)',
    padding: '10px',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageUsButton_logo: {
    width: '20px',
    height: '20px',
  },
  messageUsButton_label: {
    textDecoration: 'underline',
    color: '#fff',
  },
});

const gift = (theme) => ({
  root: {},
  giftCollection: {
    backgroundColor: theme.customPalette.greyClear,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: theme.customPalette.neutralGr02,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  giftButton: {
    backgroundColor: theme.customPalette.secondaryYellowYButtons,
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    minHeight: 40,
    maxWidth: '235px',
    width: '100%',
    '& p': {
      color: '#fff',
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Poppins',
      marginBottom: '-5px',
    },
  },
  imageWrapper: {
    height: '100%',
    maxWidth: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
      maxHeight: '160px',
    },
  },
  boxStepper: {
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
  },
  image: {
    width: 'auto',
    height: 'auto',
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  imageContainer: {
    width: '40%',
  },
  contentContainer: {
    width: '100%',
    padding: '16px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '32px 0',
    },
  },
  textPadding: {
    paddingLeft: '24px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '40px',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    '&:before': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  description: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 1.14,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  spacing: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // "& .MuiGrid-item": {
    //     padding: theme.spacing(2),
    // },
    [theme.breakpoints.down('sm')]: {
      // "& .MuiGrid-item": {
      //     padding: theme.spacing(2),
      // },
    },
  },
  small: {
    // height: 160,
    // marginTop: 16,
    // marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    '& $image': {
      // marginTop: -48,
      maxHeight: 250,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    '& $spacing': {
      '& .MuiGrid-item': {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiGrid-item': {
          padding: theme.spacing(1),
        },
      },
    },
  },
  mainTitle: {
    color: theme.customPalette.primaryGreenG02,
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Poppins',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  giftTitle: {
    fontSize: 18,
    fontFamily: 'Poppins',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
});

const myAddresses = (theme) => ({
  root: {
    width: '100%',
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontSize: 30,
    fontWeight: '600',
    color: theme.palette.neutralGy05,
    display: 'block',
    paddingTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  passwordContainer: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 0,
      width: 100,
      height: 100,
    },
  },
  bigAvatar: {
    margin: theme.spacing(2, 2),
    width: 128,
    height: 128,
  },
  prefixInput: {
    width: '100% !important',
    padding: '14px 55px !important',
  },
  prefixInputContainer: {
    marginTop: 17,
  },
  isDefault: {
    backgroundColor: '#bfbfbf',
  },
});

const moreCollections = (theme) => ({
  moreProduct: {
    backgroundColor: theme.customPalette.neutralGr02,
    padding: theme.spacing(10, 0),
  },
  productsGrid: {},
  title: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  product: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    height: 340,
    width: '100%',
    padding: theme.spacing(3),
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      height: 400,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  productImage: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    bottom: 0,
    maxHeight: 340,
    maxWidth: 524,
    [theme.breakpoints.down('md')]: {
      position: 'initial',
      maxWidth: 228,
    },
  },
  small: {},
  big: {
    '&$product': {
      height: 400,
      [theme.breakpoints.down('md')]: {
        height: 400,
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flexDirection: 'row',
      },
      [theme.breakpoints.down('xs')]: {
        height: 400,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
    '& $productImage': {
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        maxWidth: 524,
      },
    },
  },
});

const newsModule = (theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(0),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0),
    },
  },
  slider: {
    paddingLeft: theme.spacing(2),
    '& .slick-list': {
      width: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },
  grid: {
    marginTop: theme.spacing(5),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    padding: '16px',
    fontWeight: 500,
    fontSize: 16,
    marginTop: theme.spacing(5),
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      // marginLeft: theme.spacing(2),
      // marginRight: theme.spacing(2),
    },
  },
});

const nextCollectionModal = (theme) => ({
  modal: {},
  title: {},
  modalBody: {
    minWidth: `min(60vw, ${theme.breakpoints.values.md}px)`,
    height: '100%',
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(0),
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
      padding: theme.spacing(0),
    },
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: 36,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  sectionText: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  confirmButton: {
    minHeight: 48,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(5),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  image: {
    maxHeight: 500,
    maxWidth: 960,
  },
  imageWrapper: {
    textAlign: 'center',
  },
});

const orderAlertBar = () => ({
  orderStickyAlert: {},
});

const paymentMethod = (theme) => ({
  root: {
    width: '100%',
  },
  sectionTitle: theme.profile.sectionTitle,

  passwordContainer: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 0,
      width: 100,
      height: 100,
    },
  },
  bigAvatar: {
    margin: theme.spacing(2, 2),
    width: 128,
    height: 128,
  },
  summaryContainer: {
    margin: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
});

const promoFooter = (theme) => ({
  promoFooter: {
    position: 'fixed',
    left: 0,
    // transform: `translate3d(0,${threshold}px,0)`,
    width: '100%',
    zIndex: theme.zIndex.modal - 15,
    backgroundColor: '#fff',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  container2: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  divider: {
    borderLeft: `1px solid ${theme.customPalette.neutralGy05}`,
  },
  cancelButton: {
    marginRight: 24,
  },
  iconClose: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
  icon: {
    width: 24,
    height: 24,
  },
  mobileContainer: {
    padding: 16,
  },
  ctaButton: {
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

const changeCountryModal = (theme) => ({
  backDrop: {
    backgroundColor: theme.customPalette.primaryGreenG02,
  },
  paperProps: {
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
      marginTop: 'auto',
    },
  },
  flagImg: {
    height: 22,
  },
  flagLabel: {
    marginBottom: '-5px',
  },
  modalHeader: {
    borderBottom: 0,
    boxShadow: '0 4px 4px 0 rgb(215 215 215 / 25%)',
    '& h5': {
      fontFamily: 'Poppins !important',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  modalBody: {
    minWidth: `min(60vw, ${theme.breakpoints.values.md}px)`,
    height: '100%',
    padding: theme.spacing(7.5, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(2),
    },
  },
  chooseCountryBox: {
    display: 'flex',
    border: `solid 1px ${theme.customPalette.neutralGr03}`,
    padding: `19.5px 16px`,
    marginTop: 8,
    marginBottom: 8,
    width: 336,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& span': {
      fontFamily: 'Poppins',
      fontSize: 20,
      fontWeight: 500,
      paddingLeft: 8,
    },
    '&:hover': {
      cursor: 'pointer',
      border: `solid 1px ${theme.customPalette.secondaryYellowYButtons}`,
    },
  },
});

const subscribeForm = (theme) => ({
  formContainer: {
    width: '100%',
    paddingTop: '40px',
    paddingBottom: '40px',
    background: theme.customPalette.neutralGr02,
    fontFamily: 'Poppins',
    marginTop: '40px',
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: 40,
    [theme.breakpoints.up('sm')]: {
      marginTop: '98px',
      paddingTop: '55px',
      paddingBottom: '55px',
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      marginBottom: 0,
    },
  },
  formText: {},
  divider: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  scrolletText: {
    fontSize: 10,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGr04,
    textAlign: 'left',
    display: 'block',
    lineHeight: 1,
  },
  scroller: {
    height: 64,
    width: '100%',
    margin: '0 auto',
    borderRadius: 2,
    color: '#7d7d7d',
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    // borderBottom: "2px solid " + theme.customPalette.neutralGr04,
    /*'&::-webkit-scrollbar': {
            width: 10,
            height: 50,
            margin: "0 0 0 15px",
            padding: "2px 1px 25px",
            backgroundColor: theme.customPalette.neutralGr04
        },
        '&::-webkit-scrollbar-track': {
            width: 10,
            height: 50,
            margin: "0 0 0 15px",
            padding: "2px 1px 25px",
            backgroundColor: theme.customPalette.neutralGr04
        },
        '&::-webkit-scrollbar-thumb': {
            width: 8,
            height: 23,
            borderRadius: 3,
            backgroundColor: theme.customPalette.mediumGrey
        }*/
  },
  purple: {
    width: 50,
    height: 4,
    borderRadius: 2,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 24,
    background: theme.customPalette.primaryGreenG02,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 32,
  },
  submitButton: {
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '336px',
    },
  },
  // New
  mainTitle: {
    fontSize: 30,
    fontWeight: 600,
    color: theme.customPalette.primaryGreenG02,
    fontFamily: 'Poppins',
    marginBottom: 8,
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
      marginBottom: 16,
    },
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 500,
    color: theme.customPalette.neutralGy05,
    marginBottom: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      marginBottom: 16,
    },
  },
  subtitleNew: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 500,
    color: theme.customPalette.neutralGy05,
    marginBottom: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      marginBottom: 32,
    },
    '& a': {
      color: theme.customPalette.neutralGy05,
      textDecorationColor: theme.customPalette.neutralGy05,
    },
    '& p': {
      margin: 0,
    },
  },
  inputDiv: {
    width: '100%',
    margin: '0 auto',
    '&  div:first-of-type': {
      width: '100%',
      // border: "none !important",
    },
    '& input': {
      textAlign: 'center',
      fontSize: '20px !important',
      fontWeight: 'normal',
      color: theme.customPalette.neutralGr03 + '!important',
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    '& .MuiFilledInput-root': {
      border: '0 !important',
    },
  },
  input: {
    width: '100%',
  },
  inputCustom: {
    backgroundColor: '#fff',
    // minHeight: 56,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
    marginBottom: 8,
  },
});

const subscriptionDetails = (theme) => ({
  root: {
    fontFamily: 'Poppins',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.customPalette.neutralGr02,
    backgroundColor: '#fff',
  },
  modalHeader: {
    borderBottom: 0,
    boxShadow: '0 4px 4px 0 rgb(215 215 215 / 25%)',
    '& h5': {
      fontFamily: 'Poppins !important',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  deliveryTitle: {
    fontWeight: 700,
  },
  deliveryPlanBox: {
    alignItems: 'center',
    gap: '16px',
    border: '.5px solid #d2d3d6',
    padding: 24,
    [theme.breakpoints.up('md')]: {
      gap: '40px',
    },
  },
  deliverySmall: {
    fontSize: '85%',
  },
  modalBody: {
    minWidth: `min(60vw, ${theme.breakpoints.values.md}px)`,
    height: '100%',
    padding: theme.spacing(7.5, 8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(2),
    },
  },
  modal: {
    // height: '100vh',
    '& .MuiDrawer-paper': {
      height: '100%',
    },
  },
  drawnerHeaderTitle: {
    border: 'none',
    borderBottom: '1px solid' + theme.customPalette.neutralGr02,
    '& p': {
      fontSize: '20px',
      fontWeight: 500,
    },
  },
  header: {
    backgroundColor: theme.customPalette.neutralGr02,
  },
  headerTitle: {
    fontSize: '20px',
    fontFamily: 'Poppins',
  },
  borderGrey: {
    borderColor: theme.customPalette.neutralGr02,
  },
  editIcon: {
    width: 24,
    height: 'auto',
  },
  premiumImg: {
    height: 80,
  },
  premiumImgContainer: {
    textAlign: 'center',
    marginTop: -30,
  },
  upgradeTitle: {
    fontSize: 18,
  },
  panelTitle: {
    color: theme.customPalette.neutralGy05,
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '8px',
    fontFamily: 'Poppins',
  },
  label: {
    fontSize: '16px',
    color: theme.customPalette.neutralGy05,
    fontFamily: 'Poppins',
  },
  value: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.customPalette.neutralGy05,
    fontFamily: 'Poppins',
  },
  currentPlan: {
    fontSize: '12px',
    fontWeight: 500,
    color: theme.customPalette.neutralGy05,
    display: 'block',
  },
  currentOffer: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 600,
    color: theme.customPalette.primaryGreenG02,
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
  mainTitle: {
    color: theme.customPalette.neutralGy05,
    fontWeight: 600,
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
  },
  statusChip: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.customPalette.primaryGreenG02,
  },
  currentOfferContainer: {
    padding: '10px 16px 10px 24px',
    borderBottom: '1px solid' + theme.customPalette.neutralGr02,
  },
  statusInfo: {
    fontSize: '16px',
    fontWeight: 600,
  },
  panelWrapper: {
    padding: '10px 16px 10px 24px',
    marginBottom: '30px',
  },
  panelWrapperDeliveryPlan: {
    padding: '10px 16px 10px 24px',
  },
  btnDeliveryPlan: {
    width: '100%',
    minWidth: '100%',
  },
  deliveryPlanContainer: {
    borderRight: '1px solid #f2f2f2',
    borderLeft: '1px solid #f2f2f2',
  },

  infoBox: {
    // backgroundColor: theme.customPalette.neutralGr01,
  },
  upgradeAutoPaymentTitle: {
    padding: '0px 16px',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    color: theme.customPalette.primaryGreenG02,
  },
  upgradeAutoPaymentDescription: {
    padding: '0px 16px',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
  },
  btnUpgradeAutoPayment: {
    textTransform: 'none',
    width: '100%',
    minHeight: '48px',
  },
  automaticImg: {
    marginBottom: 50,
    marginTop: 50,
  },
});

const subscriptionModal = (theme) => ({
  modal: {},
  title: {},
  modalBody: {
    padding: theme.spacing(3, 8),
  },
  modalFooter: {
    padding: theme.spacing(3, 8),
  },
  currentPlanContainer: {},
  premiumContainer: {
    backgroundColor: '#F5F5F5',
    margin: theme.spacing(3, -8),
    padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    },
  },
  premiumImg: {
    height: 100,
    width: 'auto',
  },
  premiumContent: {
    backgroundColor: '#fff',
    borderRadius: 3,
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  premiumImgContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '20%',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: '#E5E5E5',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  premiumTag: {
    color: '#fff',
    backgroundColor: '#32C5FF',
    border: '1px solid #fff',
    borderRadius: '100%',
    position: 'absolute',
    top: -30,
    left: 15,
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 12,
    padding: theme.spacing(1),
    transform: 'rotate(-30deg)',
  },
  premiumTextContainer: {
    padding: theme.spacing(5),
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
      position: 'relative',
    },
  },
  premiumButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      2,
    )}px`,
  },
  premiumButton: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  textIcon: {
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      right: theme.spacing(2),
    },
  },
  label: {
    whiteSpace: 'nowrap',
  },
  addressContainer: {
    padding: theme.spacing(3),
  },
  borderBottom: {
    borderBottom: '1px solid #E5E5E5',
  },
  stopSubscription: {
    borderLeft: '1px solid #E5E5E5',
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    [theme.breakpoints.only('xs')]: {
      borderLeft: 0,
      width: '100%',
      justifyContent: 'center',
    },
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: theme.spacing(3),
  },
});

const tabs = (theme) => ({
  bar: {
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  tabs: {
    minHeight: 'auto',
    // borderBottom: `1px solid ${theme.customPalette.neutralGr02}`,
  },
  // flexContainer: {
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   [theme.breakpoints.down('md')]: {
  //     justifyContent: 'flex-start',
  //   },
  // },

  muiTabRoot: {
    minWidth: '90px !important',
    padding: '6px 16px',
    minHeight: 40,
  },
  cpTabs: {
    fontFamily: 'Poppins',
    backgroundColor: '#fbfbfb',
  },
  tab: {
    height: 76,
    textTransform: 'none',
    color: theme.customPalette.neutralGr05,
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '60px !important',
      maxWidth: '30% !important',
    },
  },
  scrollButtons: {
    width: '30px',
    color: theme.customPalette.secondaryYellowYButtons,
    '&.scrollButtonsDesktop': {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
      },
    },
  },
  scrollButtonsDesktop: {
    display: 'flex',
  },
  customTabsStyle: {
    // "& .MuiTabScrollButton-root:first-child": {
    //     background: "black",
    //     color: "#fff",
    //     textDecoration: "none",
    //     paddingLeft: "20px",
    //     display: "block",
    // },
    // "& .MuiTabScrollButton-root:first-child::before": {
    //     content: "",
    //     background:
    //         "url('https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSj3XfIpf-OOJRCcWz4iN2CU3qMKVVbj0p0bRvKNGVo1U9pk_8ZIlyR8pWreA')",
    //     backgroundSize: "cover",
    //     position: "absolute",
    //     width: "20px",
    //     height: "20px",
    //     marginLeft: "-20px",
    // },
    // "& .MuiTabScrollButton-root:last-child": {
    //     background: "red",
    // },
  },
  indicator: {
    color: 'trasparent',
    display: 'none !important',
  },
  selected: {
    color: theme.customPalette.secondaryYellowYButtons,
    '& span': {
      color: theme.customPalette.secondaryYellowYButtons,
      fontSize: 14,
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
  },
  alignRight: {
    marginLeft: 'auto',
  },
  container: {
    display: 'block',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
});

const unsubscribeForm = (theme) => ({
  formContainer: {
    width: '100%',
  },
  iconAccordion: {
    width: '20px',
    height: '20px',
  },
  accordion: {
    border: '1px solid #d2d3d6',
    padding: 16,
    wordBreak: 'break-word',
    marginTop: 0,
  },
  formText: {},
  divider: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mainTitle: {
    paddingTop: '32px',
    paddingBottom: '40px',
    fontSize: 30,
    fontWeight: 600,
    margin: 0,
    color: theme.customPalette.darkishPurple,
    fontFamily: 'Poppins',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '56px',
    },
  },
  // New
  marketingSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  marketingBox: {
    border: '1px solid' + theme.customPalette.neutralGr03,
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    fontFamily: 'Poppins',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    wordBreak: 'break-word',
  },
  marketingBoxGDPR: {
    padding: theme.spacing(2, 0, 2, 0),
    fontFamily: 'Poppins',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    wordBreak: 'break-word',
    gap: 16,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  selectTitle: {
    fontSize: '16px',
    fontWeight: 600,
  },
  description: {
    fontSize: '16px',
    fontWeight: 'normal',
    margin: 0,
  },
  // Select style
  rootSelect: {
    width: 48,
    height: 31,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.customPalette.secondaryYellowYButtons,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.customPalette.secondaryYellowYButtons,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 29,
    height: 29,
  },
  track: {
    borderRadius: 30 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},

  // Dialog
  dialogMainTitle: {
    fontFamily: 'Poppins',
    fontSize: 30,
    fontWeight: 600,
    color: theme.customPalette.neutralGy05,
  },
  btnCancel: {
    maxWidth: '220px',
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid' + theme.customPalette.secondaryYellowYButtons,
    padding: '18px 16px',
    // '& span': {
    //   color: theme.customPalette.secondaryYellowYButtons,
    // },
  },
  btnConfirm: {
    maxWidth: '220px',
    width: '100%',
    padding: '18px 16px',
    marginLeft: 8,
    '& span': {
      // color: theme.customPalette.secondaryYellowYButtons,
    },
  },
  dialogTitle: {
    boxShadow: '0 4px 4px 0 rgba(215, 215, 215, 0.25)',
  },
  iconDialog: {
    width: 24,
    height: 24,
  },
  dialogPaper: {
    width: '100%',
    minHeight: '100vh',
    maxHeight: '100vh',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      minHeight: '80vh',
      maxHeight: '80vh',
      margin: 16,
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  boxBtnDialog: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
});

const activateProfile = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  root: {
    width: 400,
    display: 'block',
    backgroundColor: '#fff',
    padding: '30px 20px',
    borderRadius: 0,
    '@media screen and (max-width: 768px)': {
      width: '100%',
    },
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: theme.customPalette.neutralGy05,
    fontFamily: 'Poppins',
    margin: 0,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 'normal',
    color: theme.customPalette.neutralGy05,
    fontFamily: 'Poppins',
    marginBottom: theme.spacing(5),
  },
  input: {
    width: '100%',
  },
  errorText: {
    //FIXME
    marginTop: 8,
    fontSize: 16,
    color: '#f93f3f',
    '& a': {
      color: '#f93f3f',
    },
  },
  button: {
    width: '100%',
    fontWeight: 400,
    minHeight: 48,
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
    '& img': {
      width: 24,
      height: 24,
    },
  },
  img: {
    width: '36px',
    height: 'unset!important',
  },
  typ: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },
  missingFields: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
    padding: '30px 20px',
  },
  rootTyp: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  iconPass: {
    width: 24,
    height: 24,
  },
});

const unsubscribe = (theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      maxWidth: 960,
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  title: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
    },
  },
  select: {
    width: '50%',
  },
  subtitle: {
    fontSize: 16,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },
  dialogTitle: {
    boxShadow: '0 4px 4px 0 rgba(215, 215, 215, 0.25)',
  },
  iconDialog: {
    width: 24,
    height: 24,
  },
  dialogPaper: {
    // width: '100%',
    // minHeight: '100vh',
    // maxHeight: '100vh',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      // minHeight: '80vh',
      margin: 16,
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  boxBtnDialog: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  notyfyAllBtn: {
    backgroundColor: 'transparent',
    color: theme.customPalette.secondaryYellowYButtons,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const service = (theme) => ({
  mainTitle: {
    fontSize: 30,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
    marginBottom: 8,
  },
  description: {
    fontSize: 20,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
    marginBottom: 24,
  },
  btnText: {
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
  },
  box: {
    border: '1px solid' + theme.customPalette.primaryGreenG02,
    padding: '8px 16px',
    '& a': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      textDecoration: 'none',
      width: '100%',
      height: '100%',
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
});

const appStyles = {
  paymentResult,
  invalidCurrentPassword,
  packageRegular,
  packageFullKit,
  service,
  dashboard,
  addressList,
  alert,
  alertBar,
  articleCard,
  assembleHelp,
  autocomplete,
  button,
  countdown,
  dialog,
  drawerHeader,
  drawer,
  editablePanelSummaryActions,
  editablePanelRadio,
  editablePanelSummary,
  editablePanelDetails,
  editablePanelActions,
  editablePanel,
  estimatedArrival,
  figureCard,
  figureGrid,
  figureSelector,
  fixedMobileCTA,
  fluidImage,
  gallery,
  giftList,
  giftOverlay,
  iconButton,
  image,
  infoBar,
  infoCard,
  infoCards,
  input,
  link,
  linkButton,
  logo,
  logoutButton,
  mobileSlider,
  module,
  nextGift,
  nextPackageDetails,
  overlay,
  payment,
  paymentList,
  playButton,
  profileElement,
  progress,
  ribbon,
  select,
  slider,
  socialBox,
  stamp,
  statusChip,
  subscriptionHeader,
  text,
  topSpacing,
  video,
  videoDescription,
  complexItemCard,
  packageHeader,
  packageItemCard,
  nextPackage,
  notification,
  packagePackage,
  packageStepper,
  packageStatusDetails,
  aboutModule,
  assembleVideoBanner,
  appBar,
  changeAddressModal,
  changeCountryModal,
  changePaymentMethodModal,
  collectionTabs,
  contactForm,
  contactsInfo,
  emailCommunication,
  faqs,
  faqsPrivate,
  footer,
  gift,
  myAddresses,
  moreCollections,
  newsModule,
  nextCollectionModal,
  orderAlertBar,
  paymentMethod,
  profileInfo,
  promoFooter,
  publicContactForm,
  subscribeForm,
  subscriptionDetails,
  subscriptionModal,
  tabs,
  unsubscribeForm,
  userBanner,
  activateProfile,
  tutorials,
  collection,
  helpCenter,
  login,
  loginAgent,
  publicContactUs,
  publicHelpCenter,
  resetPassword,
  subscription,
  unsubscribe,
  app,
  pasaDistributor,
};

export default appStyles;
