import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Hidden, Grid, Button, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import appStyles from '../../../../config/appStyles';
import Image from '../common/Image';
import StatusChip from '../common/StatusChip';
import { describeStatus } from '../../utils/status';
import { statusType } from '../../constants';
import PackageStepper from './PackageStepper';
import Link from '../common/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useStores } from '../../stores';
import Icon from './../common/Icon';
import { Dialog, DialogHeader, DialogBody } from '../common/Dialog';
import { Divider, Tooltip } from '@material-ui/core';
import Text from '../common/Text';
import {
  formatCurrency,
  formatPayementDetails,
  formatDateInfos,
} from '../../utils/format';
import ItemCard from './ItemCard';
import Slider from './../common/Slider';
import Gift from '../Gift';
import Action from './Action';
import Notification from './Notification';
import gtm from '../../../../utils/gtm';
import CollectionExtensionBanner from './CollectionExtensionBanner';
import MissingPackageForm from './MissingPackage/MissingPackageForm';
import { countryCodes } from '../../../../stores/Constants';
import { detectAlpha2Country } from '../../../../utils/country';

const useStyles = makeStyles((theme) => appStyles.packageRegular(theme));

const TooltipContent = (props) => {
  const { classes, items } = props;
  return (
    <div className={classes.tooltipContainer}>
      {(items || []).map((el, i) => {
        return (
          <>
            <div key={'el_' + i} className={classes.itemTooltipList}>
              <Hidden mdUp>
                <Text component="strike" variant="bodyS" weight="bold">
                  {el.missing ? <strike>{el.text}</strike> : el.text}
                </Text>

                <Text variant="bodyS" weight="bold">
                  {el.missing ? <strike>{el.price}</strike> : el.price}
                </Text>
              </Hidden>
              <Hidden smDown>
                <Text variant="bodyXS">
                  {el.missing ? <strike>{el.text}</strike> : el.text}
                </Text>
                <Text variant="bodyXS" weight="bold">
                  {el.missing ? <strike>{el.price}</strike> : el.price}
                </Text>
              </Hidden>
            </div>
            {items.length - 1 > i && <Divider />}
          </>
        );
      })}
    </div>
  );
};

const PackageRegular = observer((props) => {
  const { ship, id, expandFirst, productInfo, ticket } = props;
  const classes = useStyles();
  const { t } = useTranslation('components');
  const [details, setDetails] = useState([]);
  const [availableInvoice, setAvailableInvoice] = useState(false);
  const { orderStore, uiStore, userStore } = useStores();
  const { currentOrder, orderTypes } = orderStore;
  const [openModal, setOpenModal] = useState(false);
  const { siteId } = uiStore;
  const [openTooltip, setOpenTooltip] = useState(false);
  const [expandedState, setExpandedState] = useState(false);
  const [actionHandler, setActionHandler] = useState({ handler: () => { } });
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [missingItem, setMissingItem] = useState(false);
  const [isMissingPackageOpen, setMissingPackageOpen] = useState(false);
  const [isMissingPackageFilled, setMissingPackageFilled] = useState(false);
  const collectionExtension = productInfo?.data?.collection_extension;

  const fetchActions = useCallback(() => {
    if (
      userStore.isLogged &&
      ship &&
      ship.id &&
      ship.payment &&
      ship.order &&
      ship.shipment
    ) {
      orderStore
        .getShipmentAdditionalInfo(ship)
        .then((additionalInfo) => {
          setAdditionalInfo(additionalInfo);
          if (additionalInfo && additionalInfo.action) {
            setActionHandler({
              handler: orderStore.computeAction(ship, additionalInfo.action),
            });
          }
        })
        .catch(() => { });
    }
  }, [orderStore, ship, userStore]);

  useEffect(() => {
    fetchDetails();
    fetchActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setExpandedState(expandFirst);
    if (
      ship.shipment &&
      ship.shipment.status &&
      ship.shipment.status !== 'DELIVERED'
    ) {
      setExpandedState(true);
    }
    if (additionalInfo && additionalInfo.notification) {
      setExpandedState(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('focus', fetchActions);
    if (expandedState) {
      document.addEventListener('focus', fetchActions, true);
    }
  }, [expandedState, fetchActions]);

  const handleChange = () => () => {
    setExpandedState(!expandedState);
  };

  const fetchDetails = () => {
    const pack = ship;
    if (pack && pack.payment && pack.payment.status) {
      orderStore.setCurrentShipmentDetails([]);
      orderStore
        .getShipmentDetails(pack)
        .then((details) => {
          if (
            currentOrder === orderTypes.backissue ||
            currentOrder.type === orderTypes.backissue
          ) {
            details.issues = details;
          }
          orderStore.setCurrentShipmentDetails(details);
          (details?.issues || []).forEach((issue) => {
            if (issue.missing) {
              setMissingItem(true);
            }
          });
          setDetails(details);
        })
        .catch(() => { });
    }
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const issuesToConsider =
    ship && ship.issues
      ? ship.issues
      : details && details.issues
        ? details.issues
        : [];

  const tooltipItems = issuesToConsider
    .map((el) => {
      return {
        text: el.name,
        price: formatCurrency(el.amount || 0, ship.currencyCode),
        missing: el.missing,
      };
    })
    .concat([
      {
        text: t('components:PackageHeader:shipping'),
        price: formatCurrency(
          ship.shipment && ship.shipment.shippingCost
            ? ship.shipment.shippingCost
            : 0,
          ship.currencyCode,
        ),
      },
      siteId.endsWith('US')
        ? {
          text: t('components:PackageHeader:tax'),
          price: formatCurrency(ship.taxes || 0, ship.currencyCode),
        }
        : undefined,
    ])
    .filter((el) => !!el);

  // Invoice
  useEffect(() => {
    if (ship) {
      orderStore.checkInvoiceAvailability(ship).then((has) => {
        setAvailableInvoice(has);
      });
    }
  }, [ship, orderStore]);

  const active = (details?.issues || []).find((it) => {
    return it.sequence >= collectionExtension?.startingIssue;
  });
  if (collectionExtension?.startingIssue) {
    localStorage.setItem('startingIssue', collectionExtension?.startingIssue);
  }

  (details?.issues || []).find((it) => {
    if (it.sequence === collectionExtension?.startingIssue) {
      localStorage.setItem(
        'lastDispatch',
        it.package -
        (it.package -
          (it.package + collectionExtension?.amountConsecutiveDispatches)),
      );
      return (
        it.package -
        (
          it.package -
          (it.package + collectionExtension?.amountConsecutiveDispatches)
        ).package
      );
    }
  });

  const enabledCountries = [
    countryCodes.cz,
    countryCodes.de,
    countryCodes.el,
    countryCodes.it,
    countryCodes.pl,
    countryCodes.sk,
    countryCodes.uk,
    countryCodes.us,
    countryCodes.at,
    countryCodes.nl,
  ];

  const showDamageLink = (date) => {
    const time = date.getTime();
    const difference = Math.abs(new Date().getTime() - time);
    const daysDifference = difference / (1000 * 3600 * 24);

    return (
      Math.round(daysDifference) <=
      parseInt(process.env.REACT_APP_PACKAGE_MISSING_DAYS)
    );
  };

  const isDelivered = (ship = {}) => {
    return !!(
      (ship?.shipment?.status || '').toLowerCase() === statusType.DELIVERED &&
      ship?.shipment?.shippingDate
    );
  };

  return (
    <Box mt={expandedState ? 1 : 0}>
      <Accordion
        expanded={expandedState}
        onChange={handleChange(id)}
        className={classes.accordion}>
        <AccordionSummary
          expandIcon={
            expandedState ? (
              <Image className={classes.expandedimg} name="less" />
            ) : (
              <Image className={classes.expandedimg} name="plus_tangerine" />
            )
          }
          className={classes.accordionSummary}
          aria-controls={`${id}-content`}
          id={`${id}-header`}>
          <Box width="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between">
              {currentOrder === orderTypes.backissue ? (
                <>
                  {ship.shipment?.paymentDate ? (
                    <span className={classes.orderSequence}>
                      {t('components:Package.packageNumberBackIssue', {
                        date: formatDateInfos(
                          ship.shipment?.paymentDate,
                          uiStore.captchaLang,
                        ),
                      })}
                    </span>
                  ) : (
                    <span className={classes.orderSequence}>
                      {t('components:Package.packageNumberBackIssueNoDate')}
                    </span>
                  )}
                </>
              ) : (
                <span className={classes.orderSequence}>
                  {t('components:Package.packageNumber', {
                    number: ship.sequence,
                  })}
                </span>
              )}

              {ship.shipment.statusCode === 'PO' ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  {ship.shipment && ship.shipment.status && (
                    <Box pr={2}>
                      <StatusChip
                        className={classes.statusPO}
                        {...describeStatus(
                          statusType.SHIPPING,
                          ship.shipment.status,
                        )}
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  {ship.shipment && ship.shipment.status && (
                    <Box pr={2}>
                      <StatusChip
                        {...describeStatus(
                          statusType.SHIPPING,
                          ship.shipment.status,
                        )}
                      />
                    </Box>
                  )}
                  {ship.payment && ship.payment.status && (
                    <Box>
                      <StatusChip
                        status={ship.shipment.status}
                        paymentMethod={ship.payment && ship.payment?.method}
                        {...describeStatus(
                          statusType.PAYMENT,
                          ship.payment.status,
                          ship.payment?.method,
                        )}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {!expandedState && (
            <Box className={classes.modelAccordionSummary}>
              {ship.ref && (
                <span>
                  {t('components:PackageRegular.orderN')}
                  &nbsp;
                  <b>{ship.ref}</b>
                </span>
              )}
              <div className={classes.deliveryItemText}>
                <span>
                  {t(
                    isDelivered(ship)
                      ? 'components:PackageRegular.deliveryDate'
                      : 'components:PackageRegular.estimatedDeliveryDate',
                  )}
                </span>
                <div>
                  {isDelivered(ship)
                    ? formatDateInfos(
                      ship?.shipment?.shippingDate,
                      uiStore.captchaLang,
                    )
                    : ship?.shipment?.shippingDateEstimated?.from &&
                      ship?.shipment?.shippingDateEstimated?.to
                      ? formatDateInfos(
                        ship?.shipment?.shippingDateEstimated?.from,
                        uiStore.captchaLang,
                      ) +
                      ' - ' +
                      formatDateInfos(
                        ship?.shipment?.shippingDateEstimated?.to,
                        uiStore.captchaLang,
                      )
                      : '-'}
                </div>
              </div>
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container>
            {!isMissingPackageOpen ? (
              <>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid xs={12} md={12}>
                      <Box
                        className={classes.stepperContainer}
                        display="flex"
                        justifyContent="space-between"
                        width="100%">
                        <Box width="100%" p={2} className={classes.boxStepper}>
                          <PackageStepper
                            status={ship ? ship.shipment.status : ''}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  {/* Stepper */}
                </Grid>

                <Grid xs={12} md={12}>
                  {missingItem ? (
                    <Notification
                      notification={{ name: 'MISSING_ISSUE' }}></Notification>
                  ) : (
                    <Notification
                      notification={
                        additionalInfo && additionalInfo.notification
                      }
                      elapse={additionalInfo?.elapse}
                    />
                  )}
                </Grid>

                {collectionExtension?.isActive &&
                  active?.sequence >= localStorage.getItem('startingIssue') &&
                  ship.sequence <= localStorage.getItem('lastDispatch') && (
                    <Grid xs={12}>
                      <CollectionExtensionBanner
                        collection={collectionExtension}
                      />
                    </Grid>
                  )}

                {/* Order info */}
                <Grid xs={12} md={12}>
                  <Box display="flex">
                    <Grid item xs={6} md={6}>
                      <List dense={true} className={classes.list}>
                        <ListItem className={classes.listItem}>
                          <ListItemText
                            className={classes.listItemText}
                            primary={t('components:PackageRegular.orderN')}
                            secondary={ship.ref}
                          />
                        </ListItem>

                        {currentOrder.launchStatus !== 'test' && (
                          <ListItem className={classes.listItem}>
                            <ListItemText
                              className={classes.listItemText}
                              primary={t(
                                isDelivered(ship)
                                  ? 'components:PackageRegular.deliveryDate'
                                  : 'components:PackageRegular.estimatedDeliveryDate',
                              )}
                              secondary={
                                <div className={classes.deliveryItemText}>
                                  <div>
                                    {isDelivered(ship)
                                      ? formatDateInfos(
                                        ship?.shipment?.shippingDate,
                                        uiStore.captchaLang,
                                      )
                                      : ship?.shipment?.shippingDateEstimated
                                        ?.from &&
                                        ship?.shipment?.shippingDateEstimated
                                          ?.to
                                        ? formatDateInfos(
                                          ship?.shipment?.shippingDateEstimated
                                            ?.from,
                                          uiStore.captchaLang,
                                        ) +
                                        ' - ' +
                                        formatDateInfos(
                                          ship?.shipment?.shippingDateEstimated
                                            ?.to,
                                          uiStore.captchaLang,
                                        )
                                        : '-'}
                                  </div>
                                  {ship.shipment?.trackingURL &&
                                    ship.shipment?.trackingURL !== 'null' &&
                                    uiStore.site !== countryCodes.uk && (
                                      <Link
                                        className={classes.trackingUrl}
                                        href={ship.shipment.trackingURL}
                                        target="_blank"
                                        title={t(
                                          'components:Package.trackPackage',
                                        )}
                                      />
                                    )}
                                </div>
                              }
                            />
                          </ListItem>
                        )}

                        <ListItem className={classes.listItem}>
                          <ListItemText
                            className={classes.listItemText}
                            primary={t('components:PackageRegular.total')}
                            secondary={
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-around"
                                height="100%">
                                <div className={classes.totalPopUpContainer}>
                                  <Hidden mdUp>
                                    {(ship && ship.issues) ||
                                      (details && details.issues) ? (
                                      <>
                                        <div className={classes.flex}>
                                          <span className={classes.price}>
                                            {formatCurrency(
                                              ship.amount,
                                              ship.currencyCode,
                                            )}
                                          </span>
                                          <Icon
                                            onClick={toggleModal}
                                            className={classes.icon}
                                            name="info"
                                          />
                                        </div>
                                        <Dialog open={openModal}>
                                          <DialogHeader
                                            title={t(
                                              'components:PackageHeader.summary',
                                            )}
                                            onClose={toggleModal}
                                          />
                                          <DialogBody
                                            className={classes.dialogContent}>
                                            <div className={classes.borders}>
                                              <TooltipContent
                                                classes={classes}
                                                items={tooltipItems}
                                              />
                                            </div>
                                          </DialogBody>
                                        </Dialog>
                                      </>
                                    ) : (
                                      <div>
                                        <Text variant="titleL">
                                          {formatCurrency(
                                            ship.amount,
                                            ship.currencyCode,
                                          )}
                                        </Text>
                                      </div>
                                    )}
                                  </Hidden>
                                  <Hidden smDown>
                                    {(ship && ship.issues) ||
                                      (details && details.issues) ? (
                                      <div
                                        onMouseEnter={() =>
                                          setOpenTooltip(true)
                                        }
                                        onMouseLeave={() =>
                                          setOpenTooltip(false)
                                        }
                                        className={clsx(
                                          classes.flex,
                                          classes.pointer,
                                        )}>
                                        <span className={classes.price}>
                                          {formatCurrency(
                                            ship.amount,
                                            ship.currencyCode,
                                          )}
                                        </span>
                                        <Tooltip
                                          arrow
                                          open={openTooltip}
                                          classes={{
                                            tooltip: classes.tooltip,
                                            arrow: classes.tooltipArrow,
                                          }}
                                          title={
                                            <TooltipContent
                                              classes={classes}
                                              items={tooltipItems}
                                            />
                                          }>
                                          <div
                                            className={classes.iconContainer}>
                                            <Icon
                                              className={classes.icon}
                                              name="info"
                                            />
                                          </div>
                                        </Tooltip>
                                      </div>
                                    ) : (
                                      <div>
                                        <Text variant="titleL">
                                          {formatCurrency(
                                            ship.amount,
                                            ship.currencyCode,
                                          )}
                                        </Text>
                                      </div>
                                    )}
                                  </Hidden>
                                </div>
                              </Box>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <List dense={true} className={classes.list}>
                        <ListItem className={classes.listItem}>
                          <ListItemText
                            className={classes.listItemText}
                            primary={t(
                              'components:PackageRegular.deliveryAddress',
                            )}
                            secondary={ship.shipment.shippingAddress.formatted}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemText
                            className={classes.listItemText}
                            primary={t('components:PackageRegular.paymentMade')}
                            secondary={
                              ship.shipment?.paymentDate
                                ? formatDateInfos(
                                  ship.shipment?.paymentDate,
                                  uiStore.captchaLang,
                                )
                                : '-'
                            }
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemText
                            className={classes.listItemText}
                            primary={t(
                              'components:PackageRegular.paymentMethod',
                            )}
                            secondary={
                              <span>{formatPayementDetails(ship.payment)}</span>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Box>
                </Grid>

                {ship.payment && (
                  <Grid
                    className={clsx(
                      classes.bodyHorizontalDividerMobile,
                      classes.bodyVerticalDivider,
                    )}
                    xs={12}
                    md={true}
                    item>
                    <Box
                      className={classes.paymentStatusWrapper}
                      justifyContent="center"
                      display="flex"
                      marginTop="16px"
                      marginBottom="16px"
                      onClick={() => gtm.pushEvent('click', 'payNow')}>
                      {additionalInfo && additionalInfo.action && (
                        <Action
                          className={clsx(classes.paymentAction, 'payNow')}
                          action={additionalInfo.action}
                          onAction={actionHandler.handler}
                        />
                      )}
                    </Box>
                  </Grid>
                )}
                <Grid xs={12} md={12}>
                  {details?.issues && (
                    <Grid className={clsx(classes.packageContent)} xs={12} item>
                      <Hidden smDown>
                        <Slider
                          infinite={false}
                          arrows={true}
                          slidesToShow={Math.min(details.issues.length, 4)}
                          showExtraSlides={true}
                          slidesToScroll={4}
                          responsive={[
                            {
                              breakpoint: 1024,
                              settings: {
                                slidesToShow: 1.5,
                                slidesToScroll: 1,
                              },
                            },
                          ]}>
                          {(details.issues || [])
                            .sort((a, b) => a.sequence - b.sequence)
                            .map((item, i) => (
                              <ItemCard
                                key={`${item.productCode}_${i}`}
                                item={item}
                                marked={false}
                              />
                            ))}
                        </Slider>
                      </Hidden>
                      <Hidden mdUp>
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          py={2}>
                          {(details.issues || [])
                            .sort((a, b) => a.sequence - b.sequence)
                            .map((item, i) => (
                              <Grid item xs={6}>
                                <ItemCard
                                  key={`${item.productCode}_${i}`}
                                  item={item}
                                  marked={false}
                                />
                              </Grid>
                            ))}
                        </Box>
                      </Hidden>
                    </Grid>
                  )}
                </Grid>

                <Grid xs={12} md={12}>
                  {/* TODO: gifts */}
                  {(details?.gifts || []).length > 0 && (
                    <Grid
                      className={clsx(
                        classes.bodyHorizontalDividerMobile,
                        classes.bodyHorizontalDivider,
                        classes.giftContainer,
                      )}
                      xs={12}
                      item>
                      {(details.gifts || []).map((gift) => {
                        if (!gift.missing) {
                          return (
                            <Gift
                              key={'gift_' + gift.id}
                              variant="small"
                              {...gift}
                            />
                          );
                        }
                        return null;
                      })}
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  alignContent="center"
                  alignItems="center"
                  spacing={3}>
                  <Grid item xs={12} md={6}>
                    {availableInvoice && (
                      <Box className={`${classes.missingPackage}-left`}>
                        <Button
                          className={classes.buttonCustom}
                          onClick={() => {
                            gtm.pushEvent(
                              'click_button',
                              'download_invoice',
                              'myArea_subscription',
                              'myArea',
                            );
                            orderStore.downloadInvoice(ship);
                          }}>
                          <Icon
                            className={classes.iconDownload}
                            name="download_tangerine"
                          />
                          <span className={classes.invoiceText}>
                            {t('components:Package.invoice')}
                          </span>
                        </Button>
                      </Box>
                    )}
                  </Grid>
                  {!ticket &&
                    !isMissingPackageFilled &&
                    enabledCountries.includes(detectAlpha2Country()) &&
                    ship?.shipment?.status === 'DELIVERED' &&
                    ship?.shipment?.shippingDateEstimated?.to &&
                    showDamageLink(
                      ship?.shipment?.shippingDateEstimated?.to,
                    ) && (
                      <Grid item xs={12} md={6}>
                        <Box className={`${classes.missingPackage}-right`}>
                          <Box>
                            <Typography
                              variant="caption"
                              className={classes.mainLink}
                              onClick={() => {
                                setMissingPackageOpen(true);
                                gtm.pushFormDamageEvent(
                                  null,
                                  'click_link',
                                  'damageIssue',
                                  currentOrder.short,
                                  ship.sequence,
                                );
                              }}>
                              {t('components:MissingPackage.mainLink')}
                            </Typography>
                          </Box>
                          <Box mt={1}>
                            <Typography variant="body2">
                              {t('components:MissingPackage.note', {
                                days: process.env
                                  .REACT_APP_PACKAGE_MISSING_DAYS,
                              })}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  {(ticket || isMissingPackageFilled) && (
                    <Grid item xs={12} md={6}>
                      <Box className={`${classes.missingPackage}-right`}>
                        <Box>
                          <Typography variant="caption">
                            {t('components:MissingPackage.mainLink')}
                          </Typography>
                        </Box>
                        <Box mt={1}>
                          {t(
                            `components:MissingPackage.status.${ticket?.status || 'new'
                            }`,
                          )}
                        </Box>
                        {ticket?.status === 'solved' && (
                          <Box mt={1}>
                            <Typography variant="body2">
                              {t(
                                'components:MissingPackage.statusDescription.solved',
                              )}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <MissingPackageForm
                setMissingPackageOpen={setMissingPackageOpen}
                setMissingPackageFilled={setMissingPackageFilled}
                ship={ship}
                issues={details?.issues}
                order={currentOrder}
              />
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});

export default PackageRegular;
