import axios from 'axios';
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import appStyles from "./../../../config/appStyles";
import { useStores } from "../stores";
import ItemCard from "./Package/ItemCard";
import Text from "./common/Text";
import { useTranslation } from "react-i18next";

const VideoItemGallery = observer((props) => {
  const [details, setDetails] = useState([]);
  const [productInfo, setProductInfo] = useState(null);

  const { orderStore, collectionStore } = useStores();
  const { showcases } = collectionStore;
  const { currentOrder } = orderStore;
  const { pack, className } = props;
  const { t } = useTranslation(["common", "components", "validations"]);

  useEffect(() => {
    if (pack && pack.payment && pack.payment.status) {
      orderStore.setCurrentShipmentDetails([]);
      orderStore
        .getShipmentDetails(pack)
        .then((details) => {
          orderStore.setCurrentShipmentDetails(details);
          setDetails(details);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [showcases]);

  useEffect(() => {
    collectionStore.fetchCollection(currentOrder);

    const getProductInfo = () => {
      axios
        .get(process.env.REACT_APP_GET_PRODUCT_INFO + currentOrder.variantId)
        .then((res) => setProductInfo(res.data))
        .catch(() => setProductInfo(null));
    };
    getProductInfo();

  }, [currentOrder]);

  return (
    <>
      {currentOrder &&
        (currentOrder.variantAttributes.plan === "RE" ||
          currentOrder.variantAttributes.plan === "PL") &&
        pack?.issues &&
        showcases &&
        showcases.length > 0
        ? showcases.map((filter) => {
          return pack?.issues.map((issue) => {
            if (issue.sequence === filter.sequence) {
              return (
                <Grid item xs={6} md={2} className={className}>
                  <ItemCard item={filter} marked={true} magazinePremium={productInfo?.data?.show_magazine_premium}
                    magazineStandard={productInfo?.data?.show_magazine_standard} />
                </Grid>
              );
            }
          });
        })
        : (pack?.issues || []).map((issue) => {
          return (
            <Grid item xs={6} md={2} className={className}>
              <ItemCard item={issue} magazinePremium={productInfo?.data?.show_magazine_premium}
                magazineStandard={productInfo?.data?.show_magazine_standard} />
            </Grid>
          );
        })}
      {(!pack || !pack?.issues || pack.issues.length === 0) && (
        <Box px={1}>
          <Text variant="bodyM">
            {t("components:VideoTutorial.missingAssembly")}
          </Text>
        </Box>
      )}
    </>
  );
});

export default VideoItemGallery;
