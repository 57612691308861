// @flow
/*global gproxy*/
import { observable, action, computed, runInAction, toJS } from 'mobx';
import User from '../models/User';
import i18n from 'i18next';
import uuid from 'uuid';
import { ghost } from '../utils/ghost';
import { detectAlpha2Country } from '../../../utils/country';
import Image from '../components/common/Image';
import React from 'react';
import PasaService from '../services/PasaService';
import { countryCodes, sitesId, supportedIsoCountries } from '../../../stores/Constants';
import storage from '../utils/storage';

class UserStore {
  @observable isReady = false;
  @observable isLoading = false;
  @observable errors = undefined;
  @observable isLogged = false;
  @observable isAgent = false;

  @observable user: User;
  @observable addresses = [];
  @observable subscriptions = {};
  @observable preferences = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action setError = (errorMsg) => {
    console.log('errors+++', errorMsg);
    this.errors = errorMsg;
  };

  async init() {
    this.isReady = false;
    // GProxy

    const generatePassword = () => {
      const length = 16;
      let result = '';
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        );
      }
      return result;
    };

    const register2 = (email, pass, token, result) => {
      const prefs = window.gproxy.consents.serialize({
        profile: false,
        input: {
          GDPRPromo: false,
          GDPRPromo3rd: false,
          GDPRProfiling: false,
        },
      });

      const subs = {
        newsletter: {
          email: {
            isSubscribed: true,
          },
        },
        marketing: {
          email: {
            isSubscribed: true,
          },
        },
      };

      window.gproxy.accounts.register({
        email: email,
        password: pass,
        regToken: token,
        finalizeRegistration: true,
        preferences: prefs,
        subscriptions: subs,
        profile: {
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          locale: 'en-GB',
        },
        data: {
          acquisitionSource: 'E-COMMERCE',
          acquisitionOwner: 'FHUK',
        },
        callback: function () {
          window.gproxy.accounts.login({
            callback: this.onLogin,
            loginID: email,
            password: pass,
            sessionExpiration: 0,
          });
        },
      });
    };

    const register = (email, pass, result) => {
      window.gproxy.accounts.initRegistration({
        callback: function () {
          register2(email, pass, result.token, result);
        },
      });
    };

    return ghost('gproxy').then((result) => {
      console.log(
        'GProxy is avaiable from now on using window.gproxy...',
        window.gproxy.session.native(),
      );
      if (result.event && result.event.registration) {
        console.log('result++++', result);
        register(result.event.data.email, generatePassword(), result.event);
      }
      if (result.success && result.event.succes) {
        console.log('result++++', result);
      }
      if (result.event && result.event.conflict) {
        console.log('Re-login needed...', result.event);
        let password = prompt(
          'Insert password for account [' + result.event.account.loginID + ']',
        );
        window.gproxy.oauth2.link({
          event: result.event,
          password: password,
          callback: function (res) {
            console.log('Account link result', res);
            this.getAccountInfo();
          },
        });
        return;
      }
      this.isReady = true;
    });
  }

  @action
  async login(username, password, keepMeLogged) {
    this.isAgent = false;
    this.isLoading = true;
    this._username = username;
    this._password = password;
    gproxy.accounts.login({
      callback: this.onLogin,
      loginID: username,
      password: password,
      wormhole: PasaService.isAvailable() ? 'pasa' : false,
      sessionExpiration: !!keepMeLogged ? -2 : 0,
    });
  }

  onLogin = async (response) => {
    if (response.errorCode === 0) {
      if (PasaService.isAvailable()) {
        PasaService.productsList(this._username)
          .then((response) => {
            if (
              parseInt(response.responseStatus?.ApiStatus?.code, 10) === 100
            ) {
              this.getAccountInfo();
            } else {
              if (response?.data?.products?.length > 0) {
                storage.store('isPasaUser', true);
                window.location.href =
                  '/' + detectAlpha2Country() + '/myarea/distributor';
              } else {
                this.getAccountInfo();
              }
            }
          })
          .catch(() => {
            this.getAccountInfo();
          });
      } else {
        this.getAccountInfo();
      }
    } else {
      if (PasaService.isAvailable()) {
        PasaService.login(this._username, this._password)
          .then((response) => {
            if (response.responseStatus.ApiStatus.code === 0) {
              this._resetCredentialsForm();
              window.location.href = response?.data?.redirectURL;
            } else {
              this.setIsLoading(false);
              this.setError(i18n.t('errors:login'));
            }
          })
          .catch(() => {
            this.setIsLoading(false);
            this.setError(i18n.t('errors:login'));
          });
      } else {
        this.setIsLoading(false);
        this.setError(i18n.t('errors:login'));
      }
    }
  };

  _resetCredentialsForm = () => {
    delete this._username;
    delete this._password;
  };

  changePreferences = async (preferences) => {
    console.log('this is pref+++', preferences);
    const params = {
      preferences,
      callback: (res) => {
        this.setAccountInfoResponse(res);
      },
    };
    gproxy.accounts.setAccountInfo(params);
  };

  @action
  restoreSession = () => {
    if (gproxy.session.valid()) {
      this.setIsLoading(true);
      this.getAccountInfo();
    } else {
      this.setIsLogged(false);
    }
  };

  getAccountInfo = () => {
    console.log('get account info');
    gproxy.accounts.getAccountInfo({
      callback: this.prepareAccountInfo,
      extraProfileFields: 'phones',
      include: 'profile,data,preferences,subscriptions,',
    });
  };

  getResetApi = () => {
    const self = this;
    let api = process.env.REACT_APP_GIGYA_RESET_API;
    if (self.rootStore.uiStore.site) {
      const testKey =
        'REACT_APP_GIGYA_RESET_API_' +
        self.rootStore.uiStore.site.toUpperCase();
      console.log('testKey', testKey);
      console.log('process.env[testKey]', process.env[testKey]);
      if (process.env[testKey]) {
        api = process.env[testKey];
      }
    }
    console.log('api', api);
    return api;
  };

  @action
  sendResetPassword = (id, lang, cb) => {
    let self = this;
    self.isLoading = true;
    self.setError('');
    gproxy.accounts.resetPassword({
      loginID: id,
      APIKey: self.getResetApi(),
      lang: lang ? lang.split('-')[0].split('_')[0] : 'en',
      callback: function (response) {
        self.isLoading = false;
        if (response.errorCode === 0 && cb) {
          cb(response);
        } else {
          if (PasaService.isAvailable()) {
            self.isLoading = true;
            PasaService.restorePassword(id)
              .then((res) => {
                self.isLoading = false;
                if (
                  res.responseStatus.ApiStatus.code !== 100 &&
                  res.responseStatus.ApiStatus.code !== 102 &&
                  cb
                ) {
                  res.errorCode = 0;
                  cb(res);
                } else {
                  self.setError(i18n.t('errors:resetPassword'));
                }
              })
              .catch(() => {
                self.isLoading = false;
                self.setError(i18n.t('errors:resetPassword'));
              });
          } else {
            self.setError(i18n.t('errors:resetPassword'));
          }
        }
      },
    });
  };

  @action
  sendActivateAccount = (id, lang, cb) => {
    let self = this;
    self.isLoading = true;
    self.setError('');
    const key =
      detectAlpha2Country() === countryCodes.us
        ? process.env.REACT_APP_GIGYA_ACTIVATE_KEY_US
        : process.env.REACT_APP_GIGYA_ACTIVATE_KEY;
    gproxy.accounts.resetPassword({
      loginID: id,
      APIKey: key,
      lang: lang ? lang.split('-')[0].split('_')[0] : 'en',
      callback: function (response) {
        self.isLoading = false;
        if (response.errorCode === 0 && cb) {
          cb(response);
        } else {
          if (PasaService.isAvailable()) {
            self.isLoading = true;
            PasaService.emailActivation(id)
              .then((res) => {
                self.isLoading = false;
                if (
                  res.responseStatus.ApiStatus.code !== 100 &&
                  res.responseStatus.ApiStatus.code !== 103 &&
                  cb
                ) {
                  res.errorCode = 0;
                  cb(res);
                } else {
                  self.setError(i18n.t('errors:resetPassword'));
                }
              })
              .catch(() => {
                self.isLoading = false;
                self.setError(i18n.t('errors:resetPassword'));
              });
          } else {
            self.setError(i18n.t('errors:resetPassword'));
          }
        }
      },
    });
  };

  @action
  agentSearch = (handle, secret) => {
    this.isLoading = true;
    this.isAgent = true;
    gproxy.protected.authenticate({
      client: 'agent-console',
      secret: secret,
      handle: handle,
      sessionExpiration: 0,
      callback: this.onLogin,
    });
  };

  // consents.update
  @action
  getSubscriptionText = (cb) => {
    const self = this;
    window.gproxy.consents.profile({
      // type: "compact",
      profile: self.rootStore.uiStore.siteId.endsWith('US') ? 'us' : undefined,
      lang:
        this.rootStore.uiStore.language === 'cz'
          ? 'cs'
          : this.rootStore.uiStore.language,

      require: window.gproxy.consents.grants.MISSING_ONLY,
      callback: function (response) {
        cb(response);
      },
    });
  };

  @action
  activateProfile = (newPassword, passwordResetToken, apikey, lang, cb) => {
    let self = this;
    self.isLoading = true;
    self.setError('');
    gproxy.accounts.resetPassword({
      passwordResetToken,
      newPassword,
      lang: lang ? lang.split('-')[0].split('_')[0] : 'en',
      APIKey: apikey,
      callback: function (response) {
        self.isLoading = false;
        if (response.errorCode === 0) {
          if (cb) {
            cb(response);
          }
        } else {
          self.isLoading = false;
          console.log(
            'INVALID TOKEN',
            response.errorMessage.toLowerCase().replace(/\s/g, '') ===
              'invalidtoken',
            response.errorMessage,
          );
          if (
            response.errorMessage.toLowerCase().replace(/\s/g, '') ===
            'invalidtoken'
          ) {
            self.setError('errors:invalidtoken');
          } else {
            self.setError(response.errorMessage + ': ' + response.errorDetails);
          }
        }
      },
    });
  };

  @action
  prepareAccountInfo = (response) => {
    if (response.errorCode === 0) {
      // console.debug("USER PROFILE RAW:", response);
      console.log('preferences++++', response);
      this.user = {
        ...this.user,
        UID: response.UID,
        identityId: response.data.identityId,
        name: response.profile.firstName ? response.profile.firstName : '',
        lastName: response.profile.lastName ? response.profile.lastName : '',
        phone: response.profile.phones ? response.profile.phones.number : '',
        email: response.profile.email ? response.profile.email : '',
        gender: response.profile.gender ? response.profile.gender : undefined,
        birthDay: response.profile.birthDay ? response.profile.birthDay : undefined,
        birthMonth: response.profile.birthMonth ? response.profile.birthMonth : undefined,
        birthYear: response.profile.birthYear ? response.profile.birthYear : undefined,
        avatar: response.profile.photoURL ? response.profile.photoURL : '',
        smallAvatar: response.profile.thumbnailURL
          ? response.profile.thumbnailURL
          : '',
        subscriptions: response.subscriptions ? response.subscriptions : '',
        preferences: response.preferences ? response.preferences : '',
        acquisitionOwner: response?.data?.acquisitionOwner,
        acquisitionOwnerCountry: response?.data?.acquisitionOwner?.substring(2, 4) ?? null,
      };

      this.prepareAddresses(response);
      this.setIsLogged(true);
    }

    this.isLoading = false;
    console.log(this.isLoading);
  };

  @action prepareAddresses = (response) => {
    const data = response.data.shippingAddress;
    let addresses = [];
    let fakeId = 0;

    if (data) {
      if (Array.isArray(data)) {
        data.map((el) => {
          const newRndId = uuid.v4();

          if (Array.isArray(el)) {
            addresses.push({
              ...el,
              id: fakeId++,
              rndId: uuid.v4(),
            });
          } else {
            addresses.push({
              ...el,
              id: fakeId++,
              rndId: el.rndId ? el.rndId : newRndId,
            });
          }
        });
      } else {
        addresses.push({
          ...data.shippingAddress,
          id: fakeId++,
          rndId: uuid.v4(),
        });
      }
    }

    this.addresses = addresses;
  };

  updateAddress = (address, cb) => {
    this.rootStore.uiStore.setLoader(true);
    const shippingAddress = address.map((el) => {
      const newEl = {
        ...el,
      };

      delete newEl.id;
      delete newEl.rndId;
      delete newEl.isNew;

      return newEl;
    });

    var params = {
      data: {
        shippingAddress: shippingAddress,
      },
      callback: (res) => {
        this.setAccountInfoResponse(res, cb);
      },
    };

    gproxy.accounts.setAccountInfo(params);
  };

  // setAccountInfoResponse = (response) => {
  //   console.log(response);
  //   if (response.errorCode == 400006) {
  //     alert(response.map((el) => el.message + ' - '));
  //     // check which field are invalid, correct them and call the method again...
  //   } else {
  //     this.getAccountInfo();
  //   }
  // };

  @action
  logout = async () => {
    this.isLoading = true;
    this.isAgent = false;
    gproxy.accounts.logout({
      callback: this.onLogout,
    });
  };

  @action
  onLogout = () => {
    this.user = {
      ...this.user,
      name: '',
      lastName: '',
    };

    this.rootStore.orderStore.setCurrentOrder(undefined);
    this.rootStore.orderStore.setOrders([]);
    this.rootStore.orderStore.setShipments([]);
    this.isLoading = false;
    this.setIsLogged(false);
    storage.remove('isPasaUser');
    window.location = '/' + detectAlpha2Country() + '/myarea';
  };

  updateUserData = (userInfo, openModal1, cb) => {
    let params = {
      profile: {
        firstName: userInfo.name,
        lastName: userInfo.lastName,
        email: userInfo.email,
        phones: { number: userInfo.phone },
        gender: userInfo.gender,
        birthDay: userInfo.birthDay,
        birthMonth: userInfo.birthMonth,
        birthYear: userInfo.birthYear
      },

      callback: (res) => {
        this.setAccountInfoResponse(res, cb);
      },
    };

    if (userInfo.newPassword && userInfo.password) {
      params['newPassword'] = userInfo.newPassword;
      params['password'] = userInfo.password;
    }

    this.rootStore.uiStore.setLoader(true);
    gproxy.accounts.setAccountInfo(params);
  };

  updateUserPreferences = (preference) => {
    let params = {
      preference,
    };
    console.log('PARms', params);

    this.rootStore.uiStore.setLoader(true);
    gproxy.accounts.setAccountInfo(params);
  };

  setAccountInfoResponse = (response, cb) => {
    if (response.errorCode === 400006) {
      this.rootStore.uiStore.setLoader(false);
      this.rootStore.uiStore.openModal(
        response.map((el) => el.message + ' - '),
        true,
      );
      // check which field are invalid, correct them and call the method again...
    } else if (response.errorCode === 403042) {
      // invalid current password
      this.rootStore.uiStore.setLoader(false);
      document.getElementById(
        'id-currentPassword',
      ).parentNode.style.borderColor = 'red';

      let p = document.createElement('p');
      p.setAttribute('id', 'error-current-psw');
      p.classList.add('invalidCurrentPsw');
      p.style.cssText =
        'margin: 0; font-size: 12px; margin-top: 3px; font-weight: normal; line-height: 1.17; letter-spacing: normal; color: #FF3366;';
      document.getElementById('id-currentPassword').parentNode.after(p);
      document
        .getElementById('error-current-psw')
        .append(i18n.t('errors:currentPasswordInvalid'));
      document.getElementById('id-currentPassword').value = '';
    } else if (response.errorCode === 401030) {
      // Use Old Password
      this.rootStore.uiStore.setLoader(false);
      this.rootStore.uiStore.openModal(i18n.t('errors:oldPswUsed'), '', {
        icon: <Image name="warning"></Image>,
        isError: true,
      });
    } else if (response.errorCode === 0) {
      // openModal('Saved!', false);
      this.rootStore.uiStore.setLoader(false);
      this.rootStore.uiStore.openModal(i18n.t('changesSaved'), false);
      if (cb) {
        cb();
      }
      this.getAccountInfo();
    } else {
      // generic error
      this.rootStore.uiStore.setLoader(false);
      this.rootStore.uiStore.openModal(i18n.t('genericError'), '', {
        icon: <Image name="warning"></Image>,
        isError: true,
      });
    }
  };

  @action setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  @action setIsLogged = (isLogged) => {
    console.log('setIsLogged', isLogged);
    this.isLogged = isLogged;
  };

  // Profile photo
  uploadProfilePhoto = async (photo) => {
    if (/\.(jpe?g|png|gif)$/i.test(photo.name)) {
      const result = await this.toBase64(photo).catch((e) => Error(e));
      if (result instanceof Error) {
        console.log('Error: ', result.message);
      } else {
        const base64Photo = result.split(',')[1];
        console.log(base64Photo);
        const params = {
          photoBytes: base64Photo,
          publish: 'true',
          callback: this.profilePhotoResponse,
        };
        gproxy.accounts.setProfilePhoto(params);
      }
    } else {
      alert('Picture not in jpeg, png, gif format');
    }
  };

  // Profile photo
  removeProfilePhoto = async () => {
    const params = {
      profile: {
        photoURL: '',
      },
      callback: (res) => {
        this.setAccountInfoResponse(res);
      },
    };
    gproxy.accounts.setAccountInfo(params);
  };

  profilePhotoResponse = (response) => {
    if (response.errorCode === 0) {
      this.rootStore.uiStore.openModal(i18n.t('changesSaved'), false);
      this.getAccountInfo();
    } else {
      console.log(response);
      this.rootStore.uiStore.openModal(response.errorMessage, '', {
        isError: true,
      });
    }
  };

  resetPassword = (email) => {
    const params = {
      loginID: email,
    };
    return new Promise((resolve, reject) => {
      gproxy.accounts.resetPassword({
        ...params,
        callback: (response) => {
          if (response.errorCode === 0) {
            resolve(response);
          } else {
            reject(response);
          }
        },
      });
    });
  };

  changePassword = (passwordResetToken, email, newPassword) => {
    const params = {
      loginID: email,
      passwordResetToken,
      newPassword,
    };
    return new Promise((resolve, reject) => {
      gproxy.accounts.resetPassword({
        ...params,
        callback: (response) => {
          if (response.errorCode === 0) {
            resolve(response);
          } else {
            reject(response);
          }
        },
      });
    });
  };

  @action
  fetchPreferences = (email, uid) => {
    gproxy.subscriptions.list({
      email: email,
      uid: uid,
      callback: (response) => {
        if (response.errorCode === 0) {
          runInAction(() => {
            // this.subscriptions = response.subscriptions;
            this.preferences = response.preferences;
          });
        } else {
          this.rootStore.uiStore.openModal(response.errorDetails, '', {
            isError: true,
          });
        }
      },
    });
  };

  @action
  fetchSubscriptions = (email, uid) => {
    gproxy.subscriptions.list({
      email: email,
      uid: uid,
      callback: (response) => {
        if (response.errorCode === 0) {
          runInAction(() => {
            this.subscriptions = response.subscriptions;
          });
        } else {
          this.rootStore.uiStore.openModal(response.errorDetails, '', {
            isError: true,
          });
        }
      },
    });
  };

  @action
  getSubscriptions = (email, uid) => {
    gproxy.subscriptions.list({
      email: email,
      uid: uid,
      callback: (response) => {
        if (response.errorCode === 0) {
          runInAction(() => {
            return response.subscriptions;
          });
        } else {
          this.rootStore.uiStore.openModal(response.errorDetails, '', {
            isError: true,
          });
        }
      },
    });
  };
  @action
  subscribe = (req) => {
    return new Promise((resolve, reject) => {
      gproxy.subscriptions.upsert({
        ...req,
        callback: (response) => {
          if (response.errorCode === 0) {
            runInAction(() => {
              this.subscriptions = req.subscriptions;
            });
            resolve(response);
          } else {
            reject(response);
          }
        },
      });
    });
  };

  @computed
  get hasSubscriptions() {
    let subs = this.subscriptions;
    if (subs) {
      const newSubs = toJS(subs);
      // Old hassubscriptions
      // return (
      //   newSubs !== {} &&
      //   Object.keys(newSubs).length > 0 &&
      //   Object.keys(newSubs).find((el) => !!newSubs[el])
      // );
      return (
        newSubs !== {} &&
        Object.keys(newSubs).length > 0 &&
        Object.keys(newSubs).find((el) => !!newSubs[el] && el === 'newsletter')
      );
    }
    return false;
  }

  @computed
  get hasPreferences() {
    let prefs = this.preferences;
    // console.debug("prefs: ", prefs);
    if (prefs) {
      const newPrefs = toJS(prefs);
      // console.debug("newPrefs2: ", newPrefs);
      return (
        newPrefs !== {} &&
        Object.keys(newPrefs).length > 0 &&
        Object.keys(newPrefs).find((el) => !!newPrefs[el])
      );
    }
    return false;
  }

  hasAgentCookie = () => {
    return gproxy.session && gproxy.session.trusted();
  };

  hasLoginCookie = () => {
    return !!(
      gproxy.session &&
      (gproxy.session.valid() || gproxy.session.native())
    );
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    getCountryFromAcquisitionOwner = (acquisitionOwner) => {
      return acquisitionOwner === countryCodes.uk.toUpperCase() ? supportedIsoCountries.gb : acquisitionOwner;
    }
}

export default UserStore;
